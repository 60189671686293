import React, {useState, useEffect}from 'react';
import ViewHeader from './../../components/HeaderView';
import Loader from './../../components/Loader';
import Table from './../../components/Table/index';
import Container from './../../components/Container';
import DeleteDialog from './../../components/DeleteDialog';
import TableIconButton from './../../components/TableIconButton';
import Services from './../../services/index';
import Alert from './../../components/Alert';
import SectionsDialog from './SectionsDialogView';
import moment from 'moment-timezone';
import {
  useFirestoreConnect, isLoaded, useFirestore
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon } from '@material-ui/icons';
import { Box, IconButton } from '@material-ui/core';

const header = [
  {
    prop: 'name',
    name: 'Nombre',
  },
  {
    prop: 'description',
    name: 'Descripción',
  },
  {
    prop: 'registry',
    name: 'Registro'
  }
];

const SectionsView = () => {
  const firestore = useFirestore();
  useFirestoreConnect([
    {collection:'sections', orderBy: ['name']},
    {collection:'districts', orderBy: ['name']}
  ]);
  const sections = useSelector(state => state.firestore.ordered.sections);
  const districtsSelector = useSelector(state => state.firestore.ordered.districts);
  const [sectionId, setSectionId] = useState();
  const [openSectionsDialog, setOpenSectionsDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [mode, setMode] = useState();
  const [section, setSection] = useState({
    id: '',
    name: '',
    districtId: '',
    description: '',
    registry: ''
  });
  const [loaded, setLoaded] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if(!loaded && isLoaded(districtsSelector)) {
      let _districts = [];

      districtsSelector.forEach(district => {
        _districts.push({'name': district.name, 'id': district.id});
      });

      setDistricts(_districts);
      setLoaded(true);
    }
   
  }, [loaded, districtsSelector])

  if (!isLoaded(sections) || !loaded) {
    return <Loader />;
  }
 
  const handleClickOpenDialog = (row, mode) => {
    setMode(mode);
    setOpenSectionsDialog(true);
    setSection(row ? row : {
      name: '',
      districtId: '',
      description: ''
    });
  }

  const handleDeleteSelect = id => {
    setOpenDialogDelete(true);
    setSectionId(id);
  }

  const handleDelete = async () => {
    const contactsDocs = await Services.getCollectionWhere('contacts', 'sectionId', sectionId);

    if(contactsDocs.size) {
      setError('No se puede borrar esta sección porque tiene relación con algún contacto.');
      setOpenDialogDelete(false);
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
    else {
      firestore.collection('sections').doc(sectionId).delete()
        .then(() => {
          setOpenDialogDelete(false);
        });
    }
  }

  const handleChange = _section => {
    setSection(_section);
  }

  const getSectionCopy = (mode) => {
    const sectionCopy = {...section};
    
    if(mode == 'add') {sectionCopy.registry = moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm');}
    
    delete sectionCopy.id;

    return sectionCopy;
  }

  const add = () => {
    const sectionCopy = getSectionCopy('add');
    
    Services.addDocument('sections', sectionCopy)
      .then(() => {
        setOpenSectionsDialog(false);
      });
  }

  const update  = async () => {
    const sectionCopy = getSectionCopy('update');
    
    Services.updateDocument('sections', section.id, sectionCopy)
      .then(async () => {
        setOpenSectionsDialog(false);
      }); 
  }

  return (
    <>
      <ViewHeader Text='Secciones' />
      <Container>
      <div style={{ width: '100%' }}>
        <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
          <Box p={1}>
            <IconButton style={{backgroundColor: '#4caf50'}} onClick={() => handleClickOpenDialog(null, 'add')}>
              <AddIcon style={{color: 'white'}} />
            </IconButton>
          </Box>
        </Box>
      </div>
        <Table
          filter={['name']}
          paginated
          header={header}
          data={sections}
          extraRows={[
           {
              prop: 'district',
              name: 'Distrito',
              cell: row => (
                <>{districts.find(district => district.id == row.districtId).name}</>
              )
            },
            {
              prop: 'delete',
              name: 'Eliminar',
              cell: row => (
                <IconButton style={{backgroundColor: '#ffac33'}} onClick={() => handleDeleteSelect(row.id)}>
                  <TableIconButton
                    color={'white'}
                    icon={DeleteIcon}
                  />
                </IconButton>
              )
            },
            {
              prop: 'actions',
              name: 'Modificar',
              cell: row => (
                <IconButton style={{backgroundColor: '#ffcd38'}}  onClick={() => handleClickOpenDialog(row, 'update')}>
                  <TableIconButton
                    color={'white'}
                    icon={EditIcon}/>
                </IconButton>
              )
            }
          ]}
        />
      </Container>
      <DeleteDialog
        open={openDialogDelete}
        onAccept={() => handleDelete()}
        onCancel={() => setOpenDialogDelete(false)}
      />
      <SectionsDialog
        section={section}
        open={openSectionsDialog}
        onAccept={(mode) => mode == 'update' ? update() : add()}
        onCancel={() => setOpenSectionsDialog(false)}
        mode={mode}
        onChange={handleChange}
        districts={districts}
      />
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" 
      />
    </>
  );
};

export default SectionsView;