import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, InputLabel, Select, FormControl, MenuItem, Divider } from '@material-ui/core';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function UsersDialogView({open, onCancel, onAccept, onChange, user, mode}) {
  const  {
    name = '',
    email = '',
    password = '',
    password2 = '',
    role = ''
  } = user;
  const [invalidFields, setInvalidFields] = useState([]);
  const [errorPassword, setErrorPassword] = useState('Las contraseñas deben ser iguales.')
  
  const handleChange = event => {
    const { name, value } = event.target;
    const modified = { ...user, [name]: value };
    
    onChange(modified);
  }

  const handleBeforeSubmit = () => {
    let invalidFields = [];

    if (!name) {
      invalidFields.push('name');
    }
    if(!re.test(email)) {
      invalidFields.push('email');
    }
    if(mode == 'add' && !validPasswords()) {
      invalidFields.push('password');
    }
    
    setInvalidFields(invalidFields);

    if(!invalidFields.length) {
      onAccept();
    }
  }

  const handleBeforeCancel = () => {
    setInvalidFields([]);
    onCancel();
  }
  
  const validPasswords = () => {
    if(password.length <= 5 || password2.length <= 5) {
      setErrorPassword('Las contraseñas deben tener un minimo de 6 caracteres.')
      return false;
    }
    else 
    if(password != password2) {
      setErrorPassword('Las contraseñas deben ser iguales.')
      return false;
    }
    else {
      return true;
    }
  }

  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>{mode == 'add' ? 'Nuevo usuario' : 'Editar Usuario'}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          required
          label="Nombre"
          variant="outlined"
          value={name}
          name="name"
          onChange={handleChange}
          error={invalidFields.includes('name')}
          helperText="Favor de escribir el nombre."
        />
        <TextField
          style={{marginTop: 10}}
          fullWidth
          required
          label="Correo electronico"
          variant="outlined"
          value={email}
          name="email"
          onChange={handleChange}
          error={invalidFields.includes('email')}
          helperText="Favor de escribir el correo electronico."
        />
        <FormControl fullWidth style={{marginTop: 10}} variant="outlined">
          <InputLabel>Rol</InputLabel>
          <Select
            value={role}
            onChange={handleChange}
            name="role"
            label="Rol"
        >   
            <MenuItem value={'Representante'} >
              Representante
            </MenuItem>
            <MenuItem value={'Coordinador'}>
              Coordinador
            </MenuItem>
          </Select>
        </FormControl>
        <Divider style={{marginTop: 10}} />
        {
          mode == 'add'
          ?
            <>
              <TextField
                style={{marginTop: 10}}
                fullWidth
                required
                label="Contraseña"
                variant="outlined"  
                value={password}
                name="password"
                onChange={handleChange}
                error={invalidFields.includes('password')}
                type="password"
              />
              <TextField
                style={{marginTop: 10}}
                fullWidth
                required
                label="Confirmar contraseña"
                variant="outlined"
                value={password2}
                name="password2"
                onChange={handleChange}
                error={invalidFields.includes('password')}
                helperText={errorPassword}
                type="password"
              />
            </>
          : 
            null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBeforeCancel}  color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleBeforeSubmit} color="secondary" autoFocus>
          Aceptar
        </Button>
      </DialogActions>
  </Dialog>
  );
};
