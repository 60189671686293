import React from 'react';
import { Typography, Grid, Checkbox, Box } from '@material-ui/core';

export default function ShowMultipleQuestion({title, options, answer, onChange}) {
  return(
    <>
      <Typography><b>{title}</b></Typography>
      <Grid container>
      {
        options.map((option, index) => (
          <Grid key={index} item md={3} xs={4}> 
            <Typography align="center">{option ? option : 'Sin especificar'}</Typography>
            <Box display="flex" justifyContent="center">
              <Checkbox
                checked={answer[index]}
                onChange={() => onChange(!answer[index], index)}
              />
            </Box>
          </Grid>
        ))
      }
      </Grid>
    </>
  )
}