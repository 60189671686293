
import React, { useEffect, useState } from 'react';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Card, Typography
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import MapDirectionsRenderer from '../../hooks/MapDirectionsRenderer';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import MapIcon from '@material-ui/icons/Map';
import Alert from './../../components/Alert';
import Services from './../../services/index';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment-timezone';

const mapStyles = {        
  height: "350px",
  width: "100%"
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const mapColors = {
  'new': 'grey',
  'visited': 'green',
  'canceled': 'red'
};
const letters = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E'
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  
  result.splice(endIndex, 0, removed);

  return result;
};

export default function RoutesRouteDialogView ({open, onAccept, onCancel, contacts, 
  geoPoints, routeId, _origin, contactGeoPoints, _route}) {
  const [route, setRoute] = useState(); 
  const [contactDestinations, setContactDestinations] = useState([]);
  const [error, setError] = useState(null);
  const [origin, setOrigin] = useState();

  useEffect(() => {
    if(contacts && route && contactGeoPoints) {
      let _contactDestinations = [];
      
      route.legs.forEach((leg, i) => {
        if(route.legs.length == 1) {
          const contact = contacts.find(contact => contact.address == leg.start_address);
          const contact2 = contacts.find(contact => contact.address == leg.end_address);
          
          _contactDestinations.push({...contact});
          _contactDestinations.push({...contact2});
        }
        else {
          const contact = contacts.find(contact => contact.address == leg.start_address);
          _contactDestinations.push({...contact});

          if(i == route.legs.length -1) {
            const contact = contacts.find(contact => contact.address == leg.end_address);
            _contactDestinations.push({...contact});
          }
        }
      });

      _contactDestinations.forEach((contact, index) => {
        const contactGeoPoint = contactGeoPoints.find(contactGeoPoint => 
          contact.geoPoint.lat == contactGeoPoint.geoPoint.lat
          && contact.geoPoint.lng == contact.geoPoint.lng
        );
        
        _contactDestinations[index].status = contactGeoPoint.status;
      });

      setContactDestinations(_contactDestinations);
    }
  }, [contacts, route, contactGeoPoints]);

  useEffect(() => {
    if(_origin) {
      setOrigin(_origin);
    }
  }, [_origin])

  const handleBeforeSubmit = () => {
    let data = {};
    let contactGeoPointsCopy  = [];
    let percentage = 0;
    let countVisteds = 0;
    let contactIds = [];

    contactDestinations.forEach((contact) => {
      let contactDestination = contactGeoPoints.find(contactGeoPoint => 
        contactGeoPoint.geoPoint.lat == contact.geoPoint.lat
        && contactGeoPoint.geoPoint.lng == contact.geoPoint.lng
      );
      
      contactIds.push(contact.id);
      contactGeoPointsCopy.push({geoPoint: contact.geoPoint, status: contactDestination.status});
    });

    if(origin) {
      data.origin = origin;
    }

    if(contactGeoPointsCopy.length) {
      countVisteds = (contactGeoPointsCopy.filter(contact => contact.status == 'visited')).length
      percentage = ((countVisteds / contactGeoPointsCopy.length) * 100);

      if(isNaN(percentage)) {
        percentage = 0;
      }
    }

    data.contactGeoPoints = contactGeoPointsCopy;
    data.percentage = percentage.toFixed(2);
    data.contactIds = contactIds;

    Services.updateDocument('routes', routeId, data);
    
    setRoute();
    onAccept();
  }      
  
  const handleCancel = () => {
    onCancel();
    setRoute();
  }

  const onChangeStatus = index => {
    if(contactDestinations[index].status == 'new') {
      const contactDestinationsCopy = [...contactDestinations];
      contactDestinationsCopy[index].status = 'visited';

      setContactDestinations(contactDestinationsCopy);
    }
    else
    if(contactDestinations[index].status == 'visited') {
      const contactDestinationsCopy = [...contactDestinations];
      contactDestinationsCopy[index].status = 'canceled';

      setContactDestinations(contactDestinationsCopy);
    }
    else
    if(contactDestinations[index].status == 'canceled') {
      const contactDestinationsCopy = [...contactDestinations];
      contactDestinationsCopy[index].status = 'new';

      setContactDestinations(contactDestinationsCopy);
    }
  }

  const onDragEnd = result => {
    if (!result.destination || result.destination.index != 0) {
      return;
    }

    const items = reorder(
      contactDestinations,
      result.source.index,
      result.destination.index
    );
    
    setOrigin(contactDestinations[result.source.index].geoPoint);
    setContactDestinations(items); 
  }

  return (
    <>
    <Dialog 
      fullScreen 
      TransitionComponent={Transition}
      fullWidth
      open={open}
    >
      <DialogTitle>Ruta</DialogTitle>
      <DialogContent>
        <Card style={{padding: 10, marginBottom: 10}}>
          <Grid container spacing={1}>
            <Grid item md={3} xs={6}>
                <Typography><b>Nombre de ruta: </b>{_route.name}</Typography>
            </Grid>
            <Grid item md={3} xs={6}>
              <Typography><b>Representante: </b>{_route.nameRepre}</Typography>
            </Grid>
            <Grid item md={3} xs={6}>
              <Typography><b>Fecha inicial: </b>{moment(Date.parse(_route.dateInitial)).format('MM-DD-YYYY hh:mm a')}</Typography>
            </Grid>
            <Grid item md={3} xs={6}>
              <Typography><b>Fecha final: </b>{moment(Date.parse(_route.dateFinal)).format('MM-DD-YYYY hh:mm a')}</Typography>
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            {
              contactDestinations.length
              ?
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        style={{width: '100%'}}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {contactDestinations.map((contact, index) => (
                          <Draggable key={contact.id} draggableId={contact.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card key={index} style={{padding: 10, marginBottom: 10}}>
                                  <Grid container spacing={2}>
                                    <Grid item md={2} xs={2}>
                                      <MapIcon 
                                        style={{
                                          fontSize: 60, 
                                          color: mapColors[contact.status]
                                        }} 
                                        onClick={ () => onChangeStatus(index)} /> 
                                    </Grid>
                                    <Grid item md={10} xs={12}>
                                      <Typography>{'Destino: ' + letters[index]}</Typography>
                                      <Typography>{'Simpatizante: ' + contact.name + ' ' + contact.lastNames}</Typography>
                                      <Typography>{'Teléfono: ' + contact.phone}</Typography>
                                      <Typography>{'Dirección: ' + contact.address}</Typography>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              :
                null
            }
          </Grid>
          <Grid item md={6} xs={12}>
            <LoadScript
              googleMapsApiKey='AIzaSyBVjSwFA-vdEIW340NMR7nYUgHG8jW25IQ'>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={16}
              >
              {
                <MapDirectionsRenderer 
                  places={geoPoints} 
                  _origin={origin}  
                  onChangeRoute={(_route) => { if(!route) {setRoute(_route)} }}
                />
              }
              </GoogleMap>
            </LoadScript>
          </Grid>
        </Grid>    
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} style={{backgroundColor: '#36bea1', color: 'white'}}>
            Cancelar
        </Button>
        <Button onClick={handleBeforeSubmit} style={{backgroundColor: '#36bea1', color: 'white'}} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
    <Alert
      open={Boolean(error)}
      onClose={() => setError(null)}
      message={error}
      severity="error" 
    />
    </>
  );
};
