import HomeView from './HomeView';
import LogInView from './LoginView/index';
import LogOutView from './LogOutView';
import NoticePrivacy from './noticePrivacy/NoticePrivacyView';
import WebView from './web/WebView';
import SectionsView from './sections/SectionsView';
import ContactsView from './contacts/ContacsView';
import FormsView from './forms/FormsView';
import PayrolsView from './payrolls/PayrollsView';
import RoutesView from './routes/RoutesView';
import UsersView from './users/UsersView';
import FormsShowView from './forms/FormsShowView';
import ShowAnswersView from './forms/FormsAnswersView';

/* import UsersView from './users/UsersView'
import UserEditView from './users/UserEditView'
import UserCreateView from './users/UserCreateView'
 */
export { 
    HomeView, LogInView, LogOutView, NoticePrivacy, WebView, SectionsView, FormsShowView,
    ContactsView, FormsView, PayrolsView, RoutesView, UsersView, ShowAnswersView
};
