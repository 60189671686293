import React, {useState}from 'react';
import ViewHeader from './../components/HeaderView';
import Loader from './../components/Loader';
import Alert from './../components/Alert';
import Table from './../components/Table/index';
import Container from './../components/Container';
import Services from './../services/index';
import DeleteDialog from './../components/DeleteDialog';
import DistrictDialog from './DistrictDialogView';
import moment from 'moment-timezone';
import {useFirestoreConnect, isLoaded, useFirestore} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import TableIconButton from './../components/TableIconButton';
import {Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon} from '@material-ui/icons';
import {Box, IconButton} from '@material-ui/core';

const header = [
  {
    prop: 'name',
    name: 'Nombre',
  },
  {
    prop: 'registry',
    name: 'Registro'
  }
];

const HomeView = () => {
  const firestore = useFirestore();
  useFirestoreConnect([
    {collection:'districts', orderBy: ['name']}
  ]);
  const districts = useSelector(state => state.firestore.ordered.districts);
  const [districtId, setDistrictId] = useState();
  const [openDialogDistrict, setOpenDialogDistrict] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [mode, setMode] = useState();
  const [district, setDistrict] = useState({
    id: '',
    name: '',
    registry: ''
  });
  const [sections, setSections] = useState([]);
  const [error, setError] = useState(null);

  if (!isLoaded(districts)) {
    return <Loader />;
  }
 
  const handleClickOpenDialog = async (row, mode) => {
    if(row) {
      const sectionsDocs = await Services.getCollectionWhere('sections', 'districtId', row.id);

      if(sectionsDocs.size) {
        const _sections = snapToArray(sectionsDocs.docs);
        setSections(_sections);
      }
    }

    setOpenDialogDistrict(true);
    setDistrict(row ? row : {
      name: '',
      registry: ''
    });
    setMode(mode);
  }

  const snapToArray = docs => docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
  const handleDeleteSelect = id => {
    setOpenDialogDelete(true);
    setDistrictId(id);
  }

  const handleDelete = async () => {
    const sectionsDocs = await Services.getCollectionWhere('sections', 'districtId', districtId);
    const contactsDocs = await Services.getCollectionWhere('contacts', 'districtId', districtId);
    
    if(sectionsDocs.size || contactsDocs.size) {
      setError('No se puede borrar este distrito porque tiene relación con alguna sección o contacto.');
      setOpenDialogDelete(false);
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
    else {
      firestore.collection('districts').doc(districtId).delete()
      .then(() => {
        setOpenDialogDelete(false);
      })
      .catch((e) => console.log(e));
    }
  }

  const handleChange = district => {
    setDistrict(district);
  }

  const add = () => {
    delete district.id;
    district.registry = moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm');

    firestore.collection('districts').doc().set(district)
    .then(() => {
      setOpenDialogDistrict(false);
    })
    .catch((e) => console.log(e));
  }

  const update = () => {
    const id = district.id;
    delete district.id;

    firestore.collection('districts').doc(id).update(district)
      .then(() => {
        setOpenDialogDistrict(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <ViewHeader Text='Distritos' />
      <Container>
      <div style={{ width: '100%' }}>
        <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
          <Box p={1}>
            <IconButton style={{backgroundColor: '#4caf50'}} onClick={ () => handleClickOpenDialog(null, 'add')}>
              <AddIcon style={{color: 'white'}} />
            </IconButton>
          </Box>
        </Box>
      </div>
        <Table
          filter={['name']}
          paginated
          header={header}
          data={districts}
          extraRows={[
            {
              prop: 'delete',
              name: 'Eliminar',
              cell: row => (
                <IconButton style={{backgroundColor: '#ffac33'}} onClick={() => handleDeleteSelect(row.id)}>
                  <TableIconButton
                    color={'white'}
                    icon={DeleteIcon}
                  />
                </IconButton>
              )
            },
            {
              prop: 'actions',
              name: 'Modificar',
              cell: row => (
                <IconButton style={{backgroundColor: '#ffcd38'}} onClick={() => handleClickOpenDialog(row, 'update')}>
                  <TableIconButton
                    color={'white'}
                    icon={EditIcon}
                  />
                </IconButton>
              )
            }
          ]}
        />
      </Container>
      <DeleteDialog
        open={openDialogDelete}
        onAccept={() => handleDelete()}
        onCancel={() => setOpenDialogDelete(false)}
      />
      <DistrictDialog
        district={district}
        open={openDialogDistrict}
        onAccept={(mode) => mode == 'update' ? update() : add()}
        onCancel={() => setOpenDialogDistrict(false)}
        mode={mode}
        onChange={handleChange}
        sections={sections}
      />
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" 
      />
    </>
  );
};

export default HomeView;