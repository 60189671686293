import React, {useState, useEffect}from 'react';
import ViewHeader from './../../components/HeaderView';
import Loader from './../../components/Loader';
import Table from './../../components/Table/index';
import Container from './../../components/Container';
import DeleteDialog from './../../components/DeleteDialog';
import ContactsDialog from './ContactsDialogView';
import TableIconButton from './../../components/TableIconButton';
import moment from 'moment-timezone';
import {useFirestoreConnect, isLoaded, useFirestore} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon} from '@material-ui/icons';
import {Box, IconButton} from '@material-ui/core';

const header = [
  {
    prop: 'name',
    name: 'Nombre',
  },
  {
    prop: 'lastNames',
    name: 'Apellidos',
  },
  {
    prop: 'key',
    name: 'Clave de elector',
  },
  {
    prop: 'email',
    name: 'correo',
  },
];

const ContactsView = () => {
  const firestore = useFirestore();
  useFirestoreConnect([
    {collection:'contacts', orderBy: ['name']},
    {collection:'sections', orderBy: ['name']},
    {collection:'districts', orderBy: ['name']},
    {collection:'states', orderBy: ['state']}
  ]);
  const contacts = useSelector(state => state.firestore.ordered.contacts);
  const sectionsSelector = useSelector(state => state.firestore.ordered.sections);
  const districtsSelector = useSelector(state => state.firestore.ordered.districts);
  const states = useSelector(state => state.firestore.ordered.states);
  const [idContact, setIdContact] = useState();
  const [openDialogContact, setOpenDialogContact] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [mode, setMode] = useState();
  const [contact, setContact] = useState({});
  const [districts, setDistricts] = useState([]);
  const [sections, setSections] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(!loaded && isLoaded(districtsSelector) && isLoaded(sectionsSelector)) {
      let _districts = [];
      let _sections = [];

      districtsSelector.forEach(district => {
        _districts.push({'name': district.name, 'id': district.id});
      });

      sectionsSelector.forEach(district => {
        _sections.push({'name': district.name, 'id': district.id});
      });

      setDistricts(_districts);
      setSections(_sections);
      setLoaded(true);
    }
   
  }, [loaded, districtsSelector])

  if (!isLoaded(contacts) || !isLoaded(states)) {
    return <Loader />;
  }
 
  const handleClickOpenDialog = (row, mode) => {
    setMode(mode);
    setOpenDialogContact(true);
    setContact(row ? row : {
      id: '',
      city: '',
      cp: '',
      districtId: '',
      name: '',
      email: '',
      key: '',
      lastNames: '',
      name: '',
      number: '',
      phone: '',
      registry: '',
      sectionId: '',
      state: '',
      street: '',
      suburb: '',
      geoPoint: '',
      address: ''
    });
  }

  const handleDeleteSelect = id => {
    setOpenDialogDelete(true);
    setIdContact(id);
  }

  const handleDelete = () => {
    firestore.collection('contacts').doc(idContact).delete()
      .then(() => {
        setOpenDialogDelete(false);
      })
      .catch((e) => console.log(e));
  }

  const handleChange = _contact => {
    setContact(_contact);
  }

  const add = () => {
    const contactCopy = getContactCopy('add');

    firestore.collection('contacts').doc().set(contactCopy)
    .then(() => {
      setOpenDialogContact(false);
    })
    .catch((e) => console.log(e));
  }

  const getContactCopy = (mode) => {
    const contactCopy = {...contact};
    
    if(mode == 'add') {contactCopy.registry = moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm');}
    
    delete contactCopy.id;

    return contactCopy;
  }

  const update = () => {
    const contactCopy = getContactCopy('update');

    firestore.collection('contacts').doc(contact.id).update(contactCopy)
      .then(() => {
        setOpenDialogContact(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <ViewHeader Text='Contactos' />
      <Container>
      <div style={{ width: '100%' }}>
        <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
          <Box p={1}>
            <IconButton style={{backgroundColor: '#4caf50'}} onClick={ () => handleClickOpenDialog(null, 'add')}>
              <AddIcon style={{color: 'white'}} />
            </IconButton>
          </Box>
        </Box>
      </div>
        <Table
          filter={['name', 'lastNames', 'key', 'email']}
          paginated
          header={header}
          data={contacts}
          extraRows={[
            {
              prop: 'delete',
              name: 'Eliminar',
              cell: row => (
                <IconButton style={{backgroundColor: '#ffac33'}} onClick={() => handleDeleteSelect(row.id)}>
                  <TableIconButton
                    color={'white'}
                    icon={DeleteIcon}
                  />
                </IconButton>
              )
            },
            {
              prop: 'actions',
              name: 'Modificar',
              cell: row => (
                <IconButton style={{backgroundColor: '#ffcd38'}}  onClick={() => handleClickOpenDialog(row, 'update')}>
                  <TableIconButton
                    color={'white'}
                    icon={EditIcon}/>
                </IconButton>
              )
            }
          ]}
        />
      </Container>
      <DeleteDialog
        open={openDialogDelete}
        onAccept={() => handleDelete()}
        onCancel={() => setOpenDialogDelete(false)}
      />
      <ContactsDialog
        contact={contact}
        open={openDialogContact}
        onAccept={(mode) => mode == 'update' ? update() : add()}
        onCancel={() => setOpenDialogContact(false)}
        mode={mode}
        onChange={handleChange}
        districts={districts}
        sections={sections}
        cities={states[0] ? states[0].cities : []}
      />
    </>
  );
};

export default ContactsView;