import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const mapStyles = {        
  height: "350px",
  width: "100%"
};

export default function ContactMapDialogView ({open, onAccept, geoPoint}) {
  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>Ubicación</DialogTitle>
      <DialogContent>
        <LoadScript
          googleMapsApiKey='AIzaSyBVjSwFA-vdEIW340NMR7nYUgHG8jW25IQ'>
          <GoogleMap
            id='example-map'
            mapContainerStyle={mapStyles}
            draggable={false}
            zoom={16}
            center={geoPoint}
          >
          {
            <Marker
              position={geoPoint}
              draggable={false} 
            /> 
          }
          </GoogleMap>
        </LoadScript>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept} style={{backgroundColor: '#36bea1', color: 'white'}} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
