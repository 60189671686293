import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Player } from 'video-react';
import { Card, Box } from '@material-ui/core';

export default function ShowMultimedia({audioUrl, videoUrl}) {
  return(
    <>
    {
      audioUrl
      ?
        <Box display="flex" justifyContent="left">
          <ReactAudioPlayer
            src={audioUrl}
            controls
          /> 
       </Box>
      :
      <Box display="flex" justifyContent="left">
        <Card style={{ height: 445, width: 600 }} >  
          <Player>
            <source src={videoUrl} />
          </Player>
        </Card>
      </Box>
}
    </>
  )
}