import React from 'react';

export default function ShowImage({imageUrl}) {
  return (
    <div style={{width: '100%'}}>
      <img src={imageUrl} 
        style={{ 
          objectFit: 'cover', 
          height: 300,
          borderRadius: 20,  
        }}
      />
    </div>
  );
}