import React from 'react';
import { Typography, Grid, Radio , Box } from '@material-ui/core';

export default function ShowTwoOptionsQuestions({title, options, answer, onChange}) {
  return(
    <>
      <Typography><b>{title}</b></Typography>
      <Grid container>
        <Grid item md={6} xs={6}> 
          <Typography align="center">{options[0] ? options[0] : 'Sin especificar'}</Typography>
          <Box display="flex" justifyContent="center">
            <Radio
              checked={answer == 0}
              onChange={() => onChange(0)}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={6}> 
          <Typography align="center">{options[1] ? options[1] : 'Sin especificar'}</Typography>
          <Box display="flex" justifyContent="center">
            <Radio
              checked={answer == 1}
              onChange={() => onChange(1)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}