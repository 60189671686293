import React, { useState, useEffect } from 'react';
import ViewHeader from './../../components/HeaderView';
import { Container, IconButton, Box, TextField, Checkbox, Typography } from '@material-ui/core';
import Table from './../../components/Table/index';
import Alert from './../../components/Alert';
import TableIconButton from './../../components/TableIconButton';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Loader from './../../components/Loader';
import RoutesDialog from './RoutesDialogView';
import RoutesContacsDialog from './RoutesContactsDialogView';
import RoutesRouteDialog from './RoutesRouteDialogView';
import moment from 'moment-timezone';
import Services from '../../services/index';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import MapIcon from '@material-ui/icons/Map';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

const header = [
  {
    prop: 'name',
    name: 'Nombre'
  },
  {
    prop: 'nameRepre',
    name: 'Representante'
  }
]

export default function RoutesView() {
  useFirestoreConnect([
    {collection:'routes', orderBy: ['name']},
    {collection:'users', orderBy: ['name']},
    {collection:'contacts', orderBy: ['name']},
    {collection:'sections', orderBy: ['name']},
    {collection:'districts', orderBy: ['name']}
  ]);
  const routes = useSelector(state => state.firestore.ordered.routes);
  const users = useSelector(state => state.firestore.ordered.users);
  const contacts = useSelector(state => state.firestore.ordered.contacts);
  const sections = useSelector(state => state.firestore.ordered.sections);
  const districts = useSelector(state => state.firestore.ordered.districts);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState();
  const [route, setRoute] = useState({});
  const [openContactsDialog, setOpenContactsDialog] = useState(false);
  const [routeContacts, setRouteContacts] = useState([]);
  const [error, setError] = useState(null);
  const [openRouteDialog, setOpenRouteDialog] = useState(false);
  const [geoPoints, setGeoPoints] = useState();
  const [routeId, setRouteId] = useState();
  const [origin, setOrigin] = useState();
  const [contactGeoPoints, setContactGeoPoints] = useState([]);
  const [routesFiltered, setRoutesFiltered] = useState([]);
  const [district, setDistrict] = useState();
  const [section, setSection] = useState();
  const [checkBox, setCheckBox] = useState(true);

  const filterRouter = () => {
    if(checkBox) {
      setRoutesFiltered(routes);
      return;
    }

    if(!district && !section) {
      setError('Favor de seleccionar un distrito o sección.');
      return;
    }

    let _routesFiltered = [];

    if(district && section) {
      const contactsFiltered = contacts.filter(contact => 
        contact.districtId == district.id && contact.sectionId == section.id);
      
      if(!contactsFiltered.length) {
        setError('Sin resultados de busqueda.');
        return;
      }

      routes.forEach(route => {
        const contact = contactsFiltered.find(contact => route.contactIds && route.contactIds.includes(contact.id));
        
        if(contact) {
          _routesFiltered.push(route);
        }
      });
    }
    else
    if(district) {
      const contactsFiltered = contacts.filter(contact => contact.districtId == district.id);
      
      if(!contactsFiltered.length) {
        setError('Sin resultados de busqueda.');
        return;
      }

      routes.forEach(route => {
        const contact = contactsFiltered.find(contact => route.contactIds && route.contactIds.includes(contact.id));
        
        if(contact) {
          _routesFiltered.push(route);
        }
      });
    }
    else 
    if(section) {

      const contactsFiltered = contacts.filter(contact => contact.sectionId == section.id);
      
      if(!contactsFiltered.length) {
        setError('Sin resultados de busqueda.');
        return;
      }

      routes.forEach(route => {
        const contact = contactsFiltered.find(contact => route.contactIds && route.contactIds.includes(contact.id));
        
        if(contact) {
          _routesFiltered.push(route);
        }
      });
    }
    setRoutesFiltered(_routesFiltered);
  }
  
  useEffect(() => {
    if(routes) {
      filterRouter();
    }
  
  }, [routes])
  
  if (!isLoaded(routes) || !isLoaded(users) || !isLoaded(contacts)|| !isLoaded(sections) || !isLoaded(districts)) {
    return <Loader />;
  }

  const openDialog = (mode, row) => {
    setMode(mode);
    setRoute(row ? row : {
      name: '',
      userId: '',
      dateInitial: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'),
      dateFinal: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')
    });
    setOpen(true);
  }

  const add = () => {
    let copyRoute = {...route};
    
    copyRoute.registry = moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm');

    Services.addDocument('routes', copyRoute)
      .then(() => {
        setOpen(false);
      })
      .catch((error) => console.log(error))
  }

  const update = () => {
    const routeCopy = {...route};
    delete routeCopy.id;

    Services.addDocument('routes', routeCopy)
      .then(() => {
        setOpen(false);
      })
      .catch((error) => console.log(error))
  }

  const onClickOpenDialogContacts = row => {
    let _routeContacts = [];

    if(row.contactIds && row.contactIds.length) {
      contacts.forEach(contact => {
        _routeContacts.push({
          ...contact,
          inRoute: row.contactIds.includes(contact.id) ? true : false
        });
      });
    }
    else {
      contacts.forEach(contact => {
        _routeContacts.push({
          ...contact,
          inRoute: false
        });
      });
    }

    setRoute(row);
    setRouteContacts(_routeContacts.sort((a, b) => (a.inRoute < b.inRoute) ? 1 : -1));
    setOpenContactsDialog(true);
  }

  const onChangeRouteContacs = (id, value) => {
    if(!value) { 
      const routeContactsFiltered = routeContacts.filter(contact => contact.inRoute);
      
      if(routeContactsFiltered.length > 4) {
        setError('Las rutas no pueden tener más de 5 destinos.');
        return;
      }
    }

    const routeContactsCopy = [...routeContacts];
    const index = routeContactsCopy.map(item => item.id).indexOf(id);
    routeContactsCopy[index].inRoute = !value;

    setRouteContacts(routeContactsCopy);
  }

  const updateRouteContacts = async () => {
    let contactIds = [];
    let contactGeoPoints = [];

    routeContacts.forEach(contact=> {
      if(contact.inRoute) {
        contactIds.push(contact.id);
        contactGeoPoints.push({geoPoint: contact.geoPoint, status: 'new'});
      }
    });

    Services.updateDocument('routes', route.id, {contactIds: contactIds, contactGeoPoints: contactGeoPoints})
      .then(() => {
        setOpenContactsDialog(false);
      })
      .catch((e) => console.log(e));
  }

  const onClickOpenDialogRoute = row => {
    if(!row.contactIds || !row.contactIds.length) {
      setError('Esta ruta no tiene destinos.');
      setTimeout(() => {
        setError();
      }, 4000);
    }
    else {
      let places = [];
      const _routeContacts = contacts.filter(contact => row.contactIds.includes(contact.id));

      row.contactGeoPoints.forEach(geoPoint => {
        places.push({latitude: geoPoint.geoPoint.lat, longitude: geoPoint.geoPoint.lng});
      });

      if(row.origin) {setOrigin(row.origin)}
      
      setRouteId(row.id);
      setGeoPoints(places);
      setRouteContacts(_routeContacts);
      setContactGeoPoints([...row.contactGeoPoints]);
      setOpenRouteDialog(true);
      setRoute(row);
    }
  }

  const onCloseRouteDialog = () => {
    setOpenRouteDialog(false)
    setOrigin();
  }

  return (
    <>
      <ViewHeader Text='Rutas' /> 
      <Container  style={{marginTop: 20, marginBottom: 50}}>
        <div style={{ width: '100%' }}>
          <Box display="flex" p={1} m={1}>
            <Box p={1} flexGrow={1}>
              <Autocomplete
                disabled={checkBox}
                onChange={(event, newValue) => {setDistrict(newValue);}}
                options={districts}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label={'Distritos'} 
                variant="filled" 
                fullWidth />}
              />
            </Box>
            <Box p={1} flexGrow={1}>
              <Autocomplete
                disabled={checkBox}
                onChange={(event, newValue) => {setSection(newValue);}}
                options={sections}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label={'Secciones'} 
                variant="filled" 
                fullWidth />}
              />
            </Box>
            <Box flexGrow={1}>
              <div>
                <Typography align='center'>Sin filtros</Typography>
                <Box display="flex" justifyContent="center">
                  <Checkbox 
                    checked={checkBox}
                    onChange={() => setCheckBox(!checkBox)} ></Checkbox>
                </Box>
              </div>
            </Box>
            <Box p={1}>
              <IconButton style={{backgroundColor: '#ffc107'}} onClick={() => filterRouter()}>
                <SearchIcon style={{color: 'white'}} />
              </IconButton>
            </Box>
            <Box p={1}>
              <IconButton style={{backgroundColor: '#4caf50'}} onClick={() => openDialog('add', null)}>
                <AddIcon style={{color: 'white'}} />
              </IconButton>
            </Box>
          </Box>
        </div>
        <Table
          filter={['name', 'nameRepre']}
          paginated
          header={header}
          data={routesFiltered}
          extraRows={[
            {
              prop: 'dateInitial',
              name: 'Fecha inicial',
              cell: row => (
                <>{moment(Date.parse(row.dateInitial)).format('MM-DD-YYYY hh:mm a')}</> 
              )
            }, 
            {
              prop: 'dateFinal',
              name: 'Fecha final',
              cell: row => (
                <>{moment(Date.parse(row.dateFinal)).format('MM-DD-YYYY hh:mm a')}</> 
              )
            }, 
            {
              prop: 'destinationsCount',
              name: 'Puntos a visitar',
              cell: row => (
                <>{row.contactIds ? row.contactIds.length : 0}</> 
              )
            }, 
            {
              prop: 'percentage',
              name: 'Avance',
              cell: row => (
              <>{row.percentage ? row.percentage : 0.00} %</> 
              )
            }, 
            {
              prop: 'addDestination',
              name: 'Agregar destino',
              cell: row => (
                <IconButton onClick={() => onClickOpenDialogContacts(row)}>
                  <TableIconButton
                    icon={AddLocationIcon}
                  />
                </IconButton>
              )
            },
            {
              prop: 'showRoute',
              name: 'Ruta',
              cell: row => (
                <IconButton onClick={() => {onClickOpenDialogRoute(row)}}>
                  <TableIconButton
                    icon={MapIcon}
                  />
                </IconButton>
              )
            }
          ]}
        />
      </Container>
      <RoutesDialog
        route={route}
        open={open} 
        onCancel={() => setOpen(false)}
        onAccept={() => mode == 'add' ? add() : update()} 
        mode={mode}
        onChange={(_route) => setRoute(_route)}
        users={users}
      />
      <RoutesContacsDialog
        open={openContactsDialog}
        onCancel={() => setOpenContactsDialog(false)}
        onAccept={updateRouteContacts}
        onChange={onChangeRouteContacs}
        contacts={routeContacts}
      />
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" 
      />
      <RoutesRouteDialog 
        open={openRouteDialog}
        onAccept={onCloseRouteDialog}
        onCancel={onCloseRouteDialog}
        contacts={routeContacts}
        geoPoints={geoPoints}
        routeId={routeId}
        _origin={origin}
        contactGeoPoints={contactGeoPoints}
        _route={route}
      />
    </>
  );
}