import React, { useState, useEffect } from 'react';
import { DirectionsRenderer } from '@react-google-maps/api';

export default function MapDirectionsRenderer({places, _origin, onChangeRoute}) {
  const [directions, setDirections] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let waypoints = places.map(p => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true
    }));
    let origin;  
    
    if(_origin) { 
      origin = _origin
      waypoints = waypoints.filter(p => p.location.lat != _origin.lat && p.location.lng != _origin.lng);
    }
    else {
      origin = waypoints.shift().location;
    }

    const destination = waypoints.pop().location;
    const directionsService = new google.maps.DirectionsService();
   
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypoints,
        optimizeWaypoints: true,
        provideRouteAlternatives: true,
        avoidFerries: false,
        avoidHighways: false,
        avoidTolls: false,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          onChangeRoute(result.routes[0])
          setDirections(result);
        } else {
          setError(result);
        }
      }
    );
  }, [_origin]);

  if (error) {
    return <h1>{error}</h1>;
  }
  return (
    directions && (
      <DirectionsRenderer directions={directions} />
    )
  );
}