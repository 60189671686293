import React from "react";
import './styles.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import theme from './components/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import RouteAuth from './components/RouteAuth';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';

import {
  HomeView,
  LogInView,
  LogOutView,
  NoticePrivacy,
  WebView,
  SectionsView, 
  ContactsView,
  VisitsView,
  VisitsShowView,
  FormsView,
  PayrolsView,
  RoutesView,
  UsersView,
  FormsShowView,
  ShowAnswersView
} from './views';

const containerStyle = {
  backgroundColor: '#fdfdfd',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  minHeight: '100vh'
};

const bodyStyles = {
  margin: 0,
  padding: 0
};

export default () => {
  const auth = useSelector(state => state.firebase.auth);
  let user = null, loading = true;


  if (isLoaded(auth)){
    user = isEmpty(auth)? null : auth;
    loading = false;
  }
  else
    return null;

  return (
    <div style={ containerStyle }>
      <ThemeProvider theme={theme}>
        <Router>
          { user && !loading ? <Header hideHeaderLinks /> : null }
          <div style={user && !loading ? bodyStyles : null }>
            <Switch>
              <RouteAuth exact path="/login" component={LogInView} />
              <RouteAuth exact path="/logout" component={LogOutView} requireAuth />
              <RouteAuth exact path="/sections" component={SectionsView} requireAuth />
              <RouteAuth exact path="/districts" component={HomeView} requireAuth />
              <RouteAuth exact path="/" component={() => <FormsView uid={user.uid} />} requireAuth />
              <RouteAuth exact path="/payrolls" component={PayrolsView} requireAuth />
              <RouteAuth exact path="/routes" component={RoutesView} requireAuth />
              <RouteAuth exact path="/noticePrivacy" component={NoticePrivacy} /> 
              <RouteAuth exact path="/contacts" component={ContactsView} requireAuth /> 
              <RouteAuth exact path="/web" component={WebView} />
              <RouteAuth exact path="/users" component={UsersView} requireAuth />
              <RouteAuth exact path="/forms/show/:id" component={FormsShowView}/>
              <RouteAuth exact path="/forms/answers/:id" component={ShowAnswersView}/>
            </Switch>
          </div>
          { user && !loading? <Footer/> : null }
        </Router>
      </ThemeProvider>
    </div>
  )
}
