import React, {useState} from 'react';
import { 
  Dialog, DialogActions, MenuItem, DialogContent, Select, InputLabel,
  DialogTitle, TextField, FormControl, FormHelperText, Button
} from '@material-ui/core';

const SectionsDialogView = ({section, open, onCancel, onAccept, mode, onChange, districts}) => {
  const [ invalidFields, setInvalidFields ] = useState([]);

  const {
    name = '',
    districtId = '',
    description = ''
  } = section;

  const handleChange = event => {
    const { name, value } = event.target;
    const modifiedSection = { ...section, [name]: value };
    onChange(modifiedSection);
  }

  const handleBeforeSubmit = () => {
    const invalidFields = [];
    if (!name) { 
      invalidFields.push('name');
    }
    if (!districtId) { 
      invalidFields.push('district');
    }
    if (!description) { 
      invalidFields.push('description');
    }

    setInvalidFields(invalidFields);
    
    if(!invalidFields.length) {
      onAccept(mode); 
    }
  }

  const handleCancel = () => {
    setInvalidFields([]);
    onCancel();
  }

  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>{mode == 'update' ? 'Editar sección' : 'Nueva sección'}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          required
          label="Nombre"
          variant="outlined"
          value={name}
          name="name"
          onChange={handleChange}
          error={invalidFields.includes('name')}
          helperText="Favor de escribir el nombre."
        />
        <TextField
          fullWidth
          required
          label="Descripción"
          variant="outlined"
          value={description}
          name="description"
          onChange={handleChange}
          style={{marginTop: 20}}
          error={invalidFields.includes('description')}
          helperText="Favor de escribir la descripción."
        />

        <FormControl fullWidth style={{marginTop: 20}} variant="outlined" 
          required
          error={invalidFields.includes('district')}
        >
          <InputLabel>Distrito</InputLabel>
          <Select
            value={districtId}
            onChange={handleChange}
            name="districtId"
            label="Distrito"
          >
            {districts.map((district) => (
              <MenuItem key={district.id} value={district.id} >
                {district.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Favor de seleccionar un distrito.</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} style={{color: '#4caf50'}} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleBeforeSubmit} style={{color: '#4caf50'}} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
  </Dialog>
  );
};

export default SectionsDialogView;