import React  from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
/* import image from './../../web_image.png'; */
import appStore from './../../app_store.png';
import googlePlay from './../../google_play.png';

const WebView = () => {
    return (
        <div>
            <Container style={{marginTop: 30, paddingBottom: '100px'}} >
                <Typography>
                Muestreos de datos, en el lugar preciso, para toma de decisiones.
Brigadier es un sistema que permite recolectar datos, de manera directa en zonas geográficas y ciudades.
El usuario de la APP, pertenece a un equipo o grupo de trabajo que tiene un objetivo de medición, como por ejemplo identificar constumbres y tendencias de mercado.
En un tablero web, se diseñan los formularios, de manera temática y estratégica, esos formularios son accesibles en la APP por el usuario que está en las rutas o lugares definidos como puntos de interés de mercado, llena los formularios  y crea nuevos contactos.
Los formularios pueden contener, preguntas abiertas, video, audio, imagen, preguntas de selección, toma de fotografía, firma digital, y tomar la ubicación GPS del lugar donde se está llenando la información.
Esta APP va dirigido a grupos de marketing y recolectores de datos, para la posterior generación de reportes de inteligencia de negocios o gráficas de representación estadística.
Los usuarios utilizan su correo electrónico para acceder al sistema web y a la APP, registrados previamente en nuestra base de datos.
La APP no tiene costo, lo que hacemos es obtener mediante su uso un canal de obtención de datos reales en sitios específicos y sectores. Puede ser usada por un cliente directamente en su empresa o equipo de datos,  y así nosotros ofrecer servicios de análisis y  hacerle su gráfica y reportes.
Puede ser usada por agencias de marketing y negocios, para obtener la información para sus proyectos. Ya que es un generador de formatos digitales de manera dinámica.
                </Typography>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={2} md={1}></Grid>
                    <Grid item xs={10} md={4}>
                        <a href="#">
                            <img src={appStore} style={{width: '300px', marginTop: 20}}></img>
                        </a>
                    </Grid>
                    <Grid item xs={2} md={3}></Grid>
                    <Grid item xs={10} md={3}>
                    <a href="#">
                        <img src={googlePlay} style={{width: '300px'}}></img>
                    </a>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                </Grid>

                <br/>
                <Typography>
                    <b>Contacto:</b> placevalid@gmail.com
                </Typography>
                <br />
                <Grid container>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={10}>
                       {/*  <img src={image} style={{width: '100%', height: 600}}  /> */}
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default WebView;