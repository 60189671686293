import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, IconButton } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Table from '../../components/Table';
import TableIconButton from '../../components/TableIconButton';
import ContactMapDialog from './ContactMapDialogView';
import MapIcon from '@material-ui/icons/Map';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const header = [
  {
    prop: 'name',
    name: 'Nombre completo'
  },
  {
    prop: 'email',
    name: 'Correo'
  },
 
  {
    prop: 'key',
    name: 'Clave de elector'
  },
  {
    prop: 'address',
    name: 'Dirección'
  },
]

export default function RoutesContactsDialogView ({open, onCancel, onAccept, onChange, contacts}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [geoPoint, setGeoPoint] = useState();
  
  const onClickOpenMap = (_geoPoint) => {
    setOpenDialog(true);
    setGeoPoint(_geoPoint)
  }

  return (
    <>
      <Dialog 
        fullScreen 
        TransitionComponent={Transition}
        fullWidth
        open={open}
      >
        <DialogTitle>Contactos en ruta</DialogTitle>
        <DialogContent>
        <Table 
          header={header}
          data={contacts}
          paginated
          filter={['name', 'email']}
          extraRows={[
            {
              prop: 'geoPoint',
              name: 'Lat-Lng',
              cell: row => (
                <>
                  Latitud: {row.geoPoint.lat}  Longitud: {row.geoPoint.lng}
                </>
              )
            },
            {
              prop: 'checkBox',
              name: 'En ruta',
              cell: row => (
                <Checkbox 
                  style={{color: '#36bea1'}}
                  checked={row.inRoute} 
                  onChange={() => onChange(row.id, row.inRoute)}
                />
              )
            },
            {
              prop: 'showMap',
              name: 'Ver ubicación',
              cell: row => (
                <IconButton onClick={() => onClickOpenMap(row.geoPoint)}>
                  <TableIconButton
                    icon={MapIcon}
                  />
                </IconButton>
              )
            }
          ]}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} style={{backgroundColor: '#36bea1', color: 'white'}}>
            Cancelar
          </Button>
          <Button onClick={onAccept} style={{backgroundColor: '#36bea1', color: 'white'}} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <ContactMapDialog 
        open={openDialog}
        onAccept={() => setOpenDialog(false)}
        geoPoint={geoPoint}
      />
    </>
  );
};
