import firebase from './../firebase'

class Services {
  async getCollection(collection) {
    return await firebase.firestore().collection(collection).get();
  }

  async getCollectionWhere(collection, w1, w2) {
    return await firebase.firestore().collection(collection).where(w1, "==", w2).get();
  }

  async addDocument(collection, data) {
    return await firebase.firestore().collection(collection).add(data);
  }

  async addDocumentWhitId(collection, data, id) {
    return await firebase.firestore().collection(collection).doc(id).set(data);
  }

  async updateDocument(collection, id, data) {
    return await firebase.firestore().collection(collection).doc(id).update(data);
  }

  async deleteDocument(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).delete();
  }

  async uploadFile(path, _image) {
    const image = await firebase.storage().ref().child(path).put(_image);
    const imageUrl = await image.ref.getDownloadURL();
    
    return imageUrl.toString();
  }

  async deleteFile(path) {
    return await firebase.storage().ref().child(path).delete();
  }

  async delete(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).delete();
  }
}

export default new Services;