import React, {useState} from 'react';
import { 
  Button,
  Dialog, DialogActions,
  DialogContent, Card, Divider,
  DialogTitle, TextField, Typography
} from '@material-ui/core';

const DistricsDialogView = ({district, open, onCancel, onAccept, mode, onChange, sections}) => {
  const [ invalidFields, setInvalidFields ] = useState([]);

  const {
    name = '',
    registry = '',
  } = district;

  const handleChange = event => {
    const { name, value } = event.target;
    const modifiedDistrict = { ...district, [name]: value };
    onChange(modifiedDistrict);
  }

  const handleBeforeSubmit = () => {
    const invalidFields = [];
    if (!name) { 
      invalidFields.push('name');
    }

    setInvalidFields(invalidFields);
    
    if(!invalidFields.length) {
      onAccept(mode); 
    }
  }

  const handleCancel = () => {
    setInvalidFields([]);
    onCancel();
  }

  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>{mode == 'update' ? 'Editar distrito' : 'Nuevo distrito'}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          required
          label="Nombre"
          variant="outlined"
          value={name}
          name="name"
          onChange={handleChange}
          error={invalidFields.includes('name')}
          helperText="Favor de escribir el nombre."
        />
          {
          mode == 'update'
          ?
            <>
            <Typography style={{color: '#757575', fontSize: 12,  marginTop: 10}}>Secciones: </Typography>
            <Card>
              <div style={{height: 112, overflowY: 'auto', overflowX:'hidden'}}>
              { 
                sections.length
                ?
                  sections.map((section) => (
                  <div style={{margin:10}} key={section.id}>
                    <Typography>{section.name}</Typography>
                    <Divider></Divider>
                  </div>
                  ))
                :
                  <Typography>Sin secciones</Typography>
              }
              </div>
            </Card>
            </>
          :
            null
        }
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} style={{color: '4caf50'}} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleBeforeSubmit} style={{color: '4caf50'}} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
  </Dialog>
  );
};

export default DistricsDialogView;