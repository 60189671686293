import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, DialogTitle, 
  Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import  QRCode from 'qrcode.react';
export default function PayrollsDetailsDialogView ({open, onAccept, payroll}) {
  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>Nómina</DialogTitle>
      <DialogContent>
        <Typography><b>Folio: </b>  {payroll ? payroll.folio : ''}</Typography>
        <Typography><b>Representante: </b>  {payroll ? payroll.nameRepre : ''}</Typography>
        <Typography><b>Correo representante: </b>  {payroll ? payroll.emailRepre : ''}</Typography>
        <Typography><b>Coordinador local: </b>  {payroll ? payroll.nameCoordi : 'Sin definir.'}</Typography>
        <Typography><b>Correo coordinador local: </b>  {payroll ? payroll.emailCoordi : 'Sin definir.'}</Typography>
        <Typography><b>Monto: </b>  {payroll ? payroll.amount :  'Sin definir.'}</Typography>
        <Typography><b>Concepto: </b>  {payroll ? payroll.concept : ''}</Typography>
        <Typography><b>Fecha de pago: </b>  {payroll && payroll.paymentDate ? moment(Date.parse(payroll.paymentDate.toDate())).format('YYYY-MM-DD HH:mm') : 'Sin fecha de pago.'}</Typography>
        <Grid container spacing={2} style={{marginTop: 15}}>
          <Grid item xs={6} md={6}>
            <QRCode value={payroll ? payroll.userId : ''} />
          </Grid>
          <Grid item xs={6} md={6}>
            <img src={payroll ? payroll.firmaUrl : ''} 
              style={{ 
                objectFit: 'cover', 
                height: 120,
                borderRadius: 20,  
                display: 'table',
                margin: '0 auto'
              }}
            />
          </Grid>
        </Grid>

      </DialogContent> 
      <DialogActions>
        <Button onClick={onAccept} style={{color: '4caf50'}} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
  </Dialog>
  );
};