import React, {useState} from 'react';
import { 
  Button, Dialog, DialogActions, MenuItem, DialogContent, Select, InputLabel,
  DialogTitle, TextField, FormControl, FormHelperText, Slide, Typography
} from '@material-ui/core';
import Container from './../../components/Container';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const mapStyles = {        
  height: "350px",
  width: "100%"
};
const staticAddress = 'Jesús García Corona 88, Villa de Seris, 83280 Hermosillo, Son., México';
const statictPosition = {
  lat: 29.0572744, 
  lng: -110.9560032
};

const DistrictDialogView = ({contact, open, onCancel, onAccept, mode, onChange, districts, sections, cities}) => {
  const [ invalidFields, setInvalidFields ] = useState([]);
  const {
    city = '',
    cp = '',
    districtId = '',
    email = '',
    key = '',
    lastNames = '',
    number = '',
    name = '',
    phone = '',
    sectionId = '',
    state = '',
    street = '',
    suburb = '',
    geoPoint = '',
    address = ''
  } = contact;

  const isValidEmail = email => {
    const emailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegularExpression.test(email);
  }

  const handleChange = event => {
    const { name, value } = event.target;
    const modifiedContact = { ...contact, [name]: value };
    onChange(modifiedContact);
  }

  const handleBeforeSubmit = () => {
    const invalidFields = [];
    
    if (!city) { 
      invalidFields.push('city');
    }
    if (!cp) { 
      invalidFields.push('cp');
    }
    if (!districtId) { 
      invalidFields.push('district');
    }
    if (!isValidEmail(email)) { 
      invalidFields.push('email');
    }
    if (!key) { 
      invalidFields.push('key');
    }
    if (!lastNames) { 
      invalidFields.push('lastNames');
    }
    if (!number) { 
      invalidFields.push('number');
    }
    if (!name) { 
      invalidFields.push('name');
    }
    if (!phone) { 
      invalidFields.push('phone');
    }
    if (!sectionId) { 
      invalidFields.push('section');
    }
    if (!state) { 
      invalidFields.push('state');
    }
    if (!street) { 
      invalidFields.push('street');
    }
    if (!suburb) { 
      invalidFields.push('suburb');
    }

    setInvalidFields(invalidFields);
    
    if(!invalidFields.length) {
      onAccept(mode); 
    }
  }

  const handleCancel = () => {
    setInvalidFields([]);
    onCancel();
  }

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    reversegeocoding({lat, lng});
  };

  const reversegeocoding = (geoPoint) => {
    const config = { 
      headers: {  
        'Content-type': 'application/json',
        'Accept': 'application/json'
      } 
    };

    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${geoPoint.lat},${geoPoint.lng}&location_type=ROOFTOP&result_type=street_address&key=AIzaSyBVjSwFA-vdEIW340NMR7nYUgHG8jW25IQ`,
     config
    ).then(res => {
      if(res) {
        const modifiedBranch = {...contact, ['address']: res.data.results[0].formatted_address, ['geoPoint']: geoPoint};

        onChange(modifiedBranch);
      }
    }).catch((error) => console.log(error));
  }

  return (
    <Dialog 
      fullScreen 
      TransitionComponent={Transition}
      open={open}
    >
      <DialogTitle>{mode == 'update' ? 'Editar' : 'Agregar'}</DialogTitle>
      <DialogContent>
        <Container fixed>
          <FormControl fullWidth variant="outlined" required 
            error={invalidFields.includes('district')}
          >
            <InputLabel>Distrito</InputLabel>
            <Select
              value={districtId}
              onChange={handleChange}
              name="districtId"
              label="Distrito"
            >
              {districts.map((district) => (
                <MenuItem key={district.id} value={district.id} >
                  {district.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Favor de seleccionar un distrito.</FormHelperText>
          </FormControl>
          <FormControl fullWidth style={{marginTop: 10}} variant="outlined" required
            error={invalidFields.includes('section')}
          >
            <InputLabel>Sección</InputLabel>
            <Select
              value={sectionId}
              onChange={handleChange}
              name="sectionId"
              label="Sección"
            >
              {sections.map((section) => (
                <MenuItem key={section.id} value={section.id} >
                  {section.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Favor de seleccionar una sección.</FormHelperText>
          </FormControl>
          <TextField
            fullWidth
            required
            label="Nombre"
            variant="outlined"
            value={name}
            name="name"
            onChange={handleChange}
            style={{marginTop: 10}}
            error={invalidFields.includes('name')}
            helperText="Favor de escribir el nombre."
          />
          <TextField
            fullWidth
            required
            label="Apellidos"
            variant="outlined"
            value={lastNames}
            name="lastNames"
            onChange={handleChange}
            style={{marginTop: 10}}
            error={invalidFields.includes('lastNames')}
            helperText="Favor de escribir los apellidos."
          />
          <TextField
            fullWidth
            required
            label="Clave de elector"
            variant="outlined"
            value={key}
            name="key"
            onChange={handleChange}
            style={{marginTop: 10}}
            error={invalidFields.includes('key')}
            helperText="Favor de escribir la clave de elector."
          />
          <TextField
            fullWidth
            required
            label="Correo"
            variant="outlined"
            value={email}
            name="email"
            onChange={handleChange}
            style={{marginTop: 10}}
            error={invalidFields.includes('email')}
            helperText="Favor de escribir un correo valido."
          />
          <TextField
            fullWidth
            required
            label="C.P."
            variant="outlined"
            value={cp}
            name="cp"
            onChange={handleChange}
            style={{marginTop: 10}}
            type="number"
            error={invalidFields.includes('cp')}
            helperText="Favor de escribir el C.P."
          />
          <TextField
            fullWidth
            required
            label="Celular"
            variant="outlined"
            value={phone}
            name="phone"
            onChange={handleChange}
            style={{marginTop: 10}}
            type="number"
            error={invalidFields.includes('phone')}
            helperText="Favor de escribir el celular."
          />
          <TextField
            fullWidth
            required
            label="Calle"
            variant="outlined"
            value={street}
            name="street"
            onChange={handleChange}
            style={{marginTop: 10}}
            error={invalidFields.includes('street')}
            helperText="Favor de escribir la calle."
          />
          <TextField
            fullWidth
            required
            label="Numero de casa"
            variant="outlined"
            value={number}
            name="number"
            onChange={handleChange}
            style={{marginTop: 10}}
            error={invalidFields.includes('number')}
            helperText="Favor de escribir el numero de casa."
          />
          <TextField
            fullWidth
            required
            label="Colonia"
            variant="outlined"
            value={suburb}
            name="suburb"
            onChange={handleChange}
            style={{marginTop: 10}}
            error={invalidFields.includes('suburb')}
            helperText="Favor de escribir la colonia."
          />
          <FormControl fullWidth style={{marginTop: 10}} variant="outlined" required
            error={invalidFields.includes('state')}
          >
            <InputLabel>Estado</InputLabel>
            <Select
              value={state}
              onChange={handleChange}
              name="state"
              label="Estado"
            >
              <MenuItem value="Sonora" >
              Sonora
              </MenuItem>
            </Select>
            <FormHelperText>Favor de seleccionar un estado.</FormHelperText>
          </FormControl>
          <FormControl fullWidth style={{marginTop: 10}} variant="outlined" required
            error={invalidFields.includes('city')}
          >
            <InputLabel>Municipio</InputLabel>
            <Select
              value={city}
              onChange={handleChange}
              name="city"
              label="Municipio"
            >
              {cities && cities.map((city) => (
                <MenuItem key={city} value={city} >
                  {city}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Favor de seleccionar un municipio.</FormHelperText>
          </FormControl>
          <Typography style={{marginTop: 10}}>Dirección: {address ? address : staticAddress}</Typography>
          <LoadScript
            googleMapsApiKey='AIzaSyBVjSwFA-vdEIW340NMR7nYUgHG8jW25IQ'>
            <GoogleMap
              id='example-map'
              mapContainerStyle={mapStyles}
              draggable={true}
              zoom={13}
              center={geoPoint ? geoPoint : statictPosition}
            >
            {
              <Marker
                position={geoPoint ? geoPoint : statictPosition}
                onDragEnd={(e) => onMarkerDragEnd(e)}
                draggable={true} 
              /> 
            }
            </GoogleMap>
          </LoadScript>
          <Typography>Latitud: {geoPoint.lat || statictPosition.lat}  Longitud: {geoPoint.lng || statictPosition.lng}</Typography>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} style={{color: '4caf50'}} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleBeforeSubmit} style={{color: '4caf50'}} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
  </Dialog>
  );
};

export default DistrictDialogView;