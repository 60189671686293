import React, { useState } from 'react';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';

export default function RoutesDialogView ({route, open, onCancel, onAccept, mode, onChange, users}) {
  const [invalidFields, setInvalidFields] = useState([]);
  const {
    name = '',
    userId = '',
    dateInitial = '',
    dateFinal = ''
  } = route;

  const handleChange = event => {
    const {name, value} = event.target;
    onChange({...route, [name]: value});
  }

  const handleBeforeSubmit = () => {
    const invalidFields = [];
    if (!name) { 
      invalidFields.push('name');
    }
    if (!userId) { 
      invalidFields.push('userId');
    }
    if (!dateInitial) { 
      invalidFields.push('dateInitial');
    }
    if (!dateFinal) { 
      invalidFields.push('dateFinal');
    }

    setInvalidFields(invalidFields);
    
    if(!invalidFields.length) {
      onAccept(); 
    }
  }

  const handleCancel = () => {
    setInvalidFields([]);
    onCancel();
  }

  const onChangeAutocomplete = value => {
    onChange({...route, userId: value ? value.id : '', nameRepre: value ? value.name : ''});
  }

  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>{mode == 'update' ? 'Editar ruta' : 'Nueva ruta '}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          required
          label="Nombre"
          variant="outlined"
          value={name}
          name="name"
          onChange={handleChange}
          error={invalidFields.includes('name')}
          helperText="Favor de escribir el nombre."
        />
        <Autocomplete
          style={{marginTop: 10}}
          onChange={(event, newValue) => onChangeAutocomplete(newValue)}
          options={users}
          defaultValue={userId ? users[users.map(user => (user.id)).indexOf(userId)] : null}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => 
            <TextField 
              {...params} 
              label={'Representantes'} 
              variant="filled"  
              error={invalidFields.includes('userId')}
              fullWidth 
              helperText="Favor de seleccionar un representante."
            />
          }
        />
        <TextField 
          style={{marginTop: 10}}
          label="Fecha inicio"
          variant="filled"  
          fullWidth
          InputProps={{ 
            inputProps: { 
              max: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm') 
            } 
          }}
          type="datetime-local" 
          name="dateInitial"
          onChange={handleChange} 
          value={dateInitial}  
        />
        <TextField 
          style={{marginTop: 10}}
          label="Fecha fin"
          variant="filled"  
          fullWidth
          InputProps={{ 
            inputProps: { 
              min: moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm')
            } 
          }}
          type="datetime-local" 
          name="dateFinal"
          onChange={handleChange} 
          value={dateFinal}  
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} style={{color: '4caf50'}} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleBeforeSubmit} style={{color: '4caf50'}} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
