import React from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';
export default function ShowTextTwoHeader({text}) {

  let arrTexto = text.split(",");


  return(
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Typography>{arrTexto[0]}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{arrTexto[1]}</Typography>
      </Grid>
    </Grid>
  )
}

