import React, { useState } from 'react';
import ViewHeader from './../../components/HeaderView';
import Loader from './../../components/Loader';
import Table from './../../components/Table/index';
import Container from './../../components/Container';
import TableIconButton from './../../components/TableIconButton';
import Alert from './../../components/Alert';
import NotificationDialog from './NotificationDialogView';
import UsersDialog from './UsersDialogView';
import axios from 'axios';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Box, Button, IconButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Services from '../../services/index'

const header = [
  {
    prop: 'name',
    name: 'Nombre'
  },
  {
    prop: 'email',
    name: 'correo'
  }
];

export default function UsersView() {
  useFirestoreConnect(['users']);
  const users = useSelector(state => state.firestore.ordered.users);
  const [userId, setUserId] = useState();
  const [message, setMessage] = useState();
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [sendingNotification, setSendingNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState();
  const [user, setUser] = useState({});
  const [messageError, setMessageError] = useState()

  if (!isLoaded(users)) {
    return <Loader />;
  }

  const sendNotification = () => {
    setSendingNotification(true);

    let url = 'https://us-central1-place-valid.cloudfunctions.net/app';
    let data;

    if(userId == '0') {
      url += '/send-notification-users';
      data = {
        userIds: users.map(item => (item.id)),
        message: message 
      };
    }
    else {
      url += '/send-notification-user';
      data = {
        userId: userId,
        message: message 
      };
    }
    
    axios.post(url, data)
      .then(res => {
        if(res) {
          setOpenDialogSuccess(true);
          setUserId(null);
          setSendingNotification(false);
          setTimeout(() => {
            setOpenDialogSuccess(false);
          }, 3000);
        }
      })
      .catch((error) => console.log(error));
  }

  const openDialog = (mode, row) => {
    setUser(row ? row : {
      name: '',
      email: '',
      password: '',
      password2: '',
      role: 'Representante',
    });
    setMode(mode);
    setOpen(true);
  }

  const add = () => {
    axios.post('https://us-central1-place-valid.cloudfunctions.net/app/user-auth-add', {
      email: user.email,
      password: user.password,
      name: user.name
    })
    .then(res => {
      if(res.data.success) {
        Services.addDocumentWhitId(
          'users', 
          {
            name: user.name,
            email: user.email,
            role: user.role
          },
          res.data.id
        )
        .then(() => setOpen(false))
        .catch((error) => console.log(error));
      }
      else {
        setMessageError(res.data.error.message)
        setTimeout(() => {
          setMessageError(null);
        }, 5000);
      }
    })
    .catch((error) => console.log(error));
  }

  const update = () => {
    axios.post('https://us-central1-place-valid.cloudfunctions.net/app/user-auth-update', {
      email: user.email,
      name: user.name,
      id: user.id
    })
    .then(res => {
      if(res.data.success) {
        Services.updateDocument(
          'users', 
          user.id,
          {
            name: user.name,
            email: user.email,
            role: user.role
          }
        )
        .then(() => setOpen(false))
        .catch((error) => console.log(error));
      }
      else {
        console.log('Error con el servidor.')
      }
    })
    .catch((error) => console.log(error));
  }

  return (
    <>
      <ViewHeader Text='Usuarios' />
      <Container>
        <div style={{ width: '100%' }}>
          <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
            <Box p={1}>
              <Button 
                style={{backgroundColor: '#4caf50', color: 'white'}} 
                onClick={() => openDialog('add', null)}
              >
                <PersonAddIcon /> Nuevo usuaro
              </Button>
            </Box>
            <Box p={1}>
              <Button 
                style={{backgroundColor: '#4caf50', color: 'white'}} 
                onClick={() => setUserId('0')}
              >
                <NotificationsIcon /> Notificación general 
              </Button>
            </Box>
          </Box>
        </div>
        <Table
          filter={['name', 'email']}
          paginated
          header={header}
          data={users}
          extraRows={[
            {
              prop: 'notification',
              name: 'Notificación',
              cell: row => (
                <IconButton 
                  style={{backgroundColor: '#ffac33'}} 
                  onClick={() => setUserId(row.id)}
                >
                  <TableIconButton
                    color={'white'}
                    icon={NotificationsIcon}
                  />
                </IconButton>
              )
            },
            {
              prop: 'actions',
              name: 'Modificar',
              cell: row => (
                <IconButton style={{backgroundColor: '#ffcd38'}} onClick={() => openDialog('update', row)}>
                  <TableIconButton
                    color={'white'}
                    icon={EditIcon}/>
                </IconButton>
              )
            }
          ]}
        />
      </Container>
      <NotificationDialog 
        open={Boolean(userId)}
        onCancel={() => setUserId(null)}
        onAccept={() => sendNotification()}
        onChange={(_message) => setMessage(_message)}
        message={message}
        userId={userId}
        sendingNotification={sendingNotification}
      />
      <Alert
        open={openDialogSuccess}
        onClose={() => setOpenDialogSuccess(false)}
        message={userId == '0' ? 'Notificaciones enviadas con éxito!' : 'Notificación enviada con éxito!'}
        severity="success" 
      />
      <Alert
        open={Boolean(messageError)}
        onClose={() => setMessageError(null)}
        message={messageError}
        severity="error" 
      />
      <UsersDialog 
        open={open}
        onCancel={() => setOpen(false)}
        onAccept={() => mode == 'add' ? add() : update()}
        onChange={(_user) => setUser(_user)}
        user={user}
        mode={mode}
      />
    </>
  );
};