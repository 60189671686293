import React, {useEffect, useState} from 'react';
import firebase from '../../firebase';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import SearchIcon from '@material-ui/icons/Search';
import AppsIcon from '@material-ui/icons/Apps';
import {makeStyles} from '@material-ui/core/styles';
import ViewHeader from './../../components/HeaderView';


const useStyles = makeStyles(theme => ({
  headTable: {
    backgroundColor: "#414141",
    margin: `10px 0 10px 0`,
    fontWeight: 'bold',
  },
  headTableText: {
    color: "#ffffff",
    fontWeight: 'bold',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buscar: {
    marginTop: theme.spacing(1),
    backgroundColor: "#164069",
    color: "#ffffff",
  },
  excel: {
    marginTop: theme.spacing(1),
    backgroundColor: "#19692a",
    color: "#ffffff",
  },
}));

export default function ShowAnswersView({match}) {
  //useFirestoreConnect([{collection: 'quiz', doc: match.params.id}]);
  /*const form = useSelector(
    ({firestore: {data}}) => data.forms && data.forms[match.params.id]
  );*/

  const classes = useStyles();
  const [quiz, setQuiz] = useState(null);
  const [quizes, setQuizes] = useState(null);
  const [inicio, setInicio] = useState(new Date());
  const [fin, setFin] = useState(new Date());

  /*if (!isLoaded(form)) {
    return <Loader/>;
  }*/


  useEffect(() => {
    firebase.firestore().collection("quiz")
      .where("formId", "==", match.params.id)
      .where("createDate", ">=", inicio)
      .where("createDate", "<=", new Date( fin.setDate(fin.getDate() + 1)) ).get()
      .then(function (documentSnapshots) {
        setQuizes(documentSnapshots.docs.map(item => ({...item.data(), id: item.id} )));
      });

    firebase.firestore().collection('forms').doc(match.params.id).get().then((doc) => {
      let data = {...doc.data(), id: doc.data().id};

      setQuiz(data);
    });
  }, [inicio, fin]);


  const exportToCSV = (fileData = [], fileName = "", sheetName = "") => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    try {
      const ws = XLSX.utils.json_to_sheet(fileData,{skipHeader: true, origin: "A1"});
      const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
      const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
    }
    catch (ex) {
      console.log(ex);
    }
  };

  const dataBody = (arrayHeader, arrayData) => {
    let arrayB = [];
    let arrayA = [];

    let quizHeader = arrayHeader ? arrayHeader.form : [];
    for (let i in quizHeader) {
      let item = quizHeader[i];
      arrayA.push(item.title);
    }

    arrayB.push(arrayA);
    for (let i in arrayData) {
      let item = arrayData[i];
      arrayB.push(
        item.quiz.map(function (answer, j) {
          return answer.answer;
        })
      );
    }
    return arrayB;
  };

  function zfill(number, width) {
    let numberOutput = Math.abs(number);
    /* Valor absoluto del número */
    let length = number.toString().length;
    /* Largo del número */
    let zero = "0";
    /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return ("-" + numberOutput.toString());
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
      } else {
        return ((zero.repeat(width - length)) + numberOutput.toString());
      }
    }
  }

  return (
    <>
    <Grid container style={{marginBottom: "4%"}}>
      <Grid item xs={12}>
        <ViewHeader Text={quiz ? quiz.formName + "/Inscritos: " + (quizes.length) : ""}/>
      </Grid>
      <Grid item xs={4}/>
      <Grid item xs={3}>
        <TextField
          id="date"
          label="Desde"
          type="date"
          defaultValue={inicio.getFullYear() + "-" + zfill((inicio.getMonth() + 1), 2) + "-" + zfill(inicio.getDate(), 2)}
          onChange={(event) => setInicio(new Date(event.target.value))}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="date"
          label="Hasta"
          type="date"
          defaultValue={fin.getFullYear() + "-" + zfill(( fin.getMonth() + 1 ), 2) + "-" + zfill(fin.getDate(), 2)}
          className={classes.textField}
          onChange={(event) => setFin(new Date(event.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          disableElevation
          fullWidth
          className={classes.excel}
          onClick={() => exportToCSV(dataBody(quiz, quizes), "Jovenes 4T")}
        >
          <AppsIcon/>Excel
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table className="table" aria-label="simple table">
            <TableHead className={classes.headTable}>
              <TableRow>
                {headerTable()}
                <TableCell className={classes.headTableText}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowTable()}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

    </Grid>
    </>

  );

  function headerTable() {
    let array = [];
    let arrayH = [];

    let quizHeader = quiz ? quiz.form : [];
    array.push(<TableCell key={'header-33'} className={classes.headTableText}>Fecha de Creación</TableCell>);
    for (let i in quizHeader) {
      let item = quizHeader[i];
      array.push((item.type !== "twoTextInput" && item.type !== "showText" && item.title !== "Cantidad,Número de parte") ?
        <TableCell key={'header-' + i} className={classes.headTableText}>{item.title}</TableCell> : "");
      arrayH.push(item.title);
    }
    return array
  }

  function rowTable() {
    let array = [];
    let arrayB = [];

    for (let i in quizes) {
      let item = quizes[i];
      let productos = [];

      let fechaCreacion = new Date(item.createDate.seconds * 1000);
      array.push(
        <TableRow key={'answer-' + i}>
          <TableCell>{zfill(fechaCreacion.getDate(), 2) + "/" + zfill((fechaCreacion.getMonth() + 1), 2) + "/" + fechaCreacion.getFullYear()}</TableCell>
          {item.quiz.map(function (answer, j) {

            if (answer.type !== "twoTextInput" && answer.type !== "showTextTwoHeader" && answer.title !== "Cantidad, Número de parte") {
              return <TableCell key={'answ-' + j}>{answer.answer}</TableCell>
            }
            else {
              productos.push((answer.answer[0] && answer.answer[1] !== "") ?
                <Grid container key={'answ-' + j}><Grid item xs={4}> {answer.answer[0]}</Grid><Grid item xs={8}>{answer.answer[1]}</Grid></Grid> : "");
            }
          })
          }

        </TableRow>
      );
      arrayB.push(
        item.quiz.map(function (answer, j) {
          return answer.answer;
        })
      );
    }

    return array
  }


  function eliminar(id) {
    firebase.firestore().collection("quiz").doc(id).delete().then(function () {
      console.log("Document successfully deleted!");
      console.log(quizes);
    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });
  }
}