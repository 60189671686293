import React, {useState} from 'react';
import {isLoaded, useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';

import Loader from './../../components/Loader';
import ShowText from './../forms/typeOptios/ShowText';
import ShowImage from './../forms/typeOptios/ShowImage';
import ShowMultimedia from './../forms/typeOptios/ShowMultimedia';
import ShowMultipleQuestion from './../forms/typeOptios/ShowMultipleQuestion';
import ShowTextInput from './../forms/typeOptios/ShowTextInput';
import ShowTwoTextInput from './../forms/typeOptios/ShowTwoTextInput';
import ShowTextTwoHeader from './../forms/typeOptios/ShowTextTwoHeader';
import ShowTwoOptionsQuestions from './../forms/typeOptios/ShowTwoOptionsQuestions';
import {Button, Card, Container, Grid, Typography} from '@material-ui/core';
import Services from './../../services/index';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

  imageFinLogo: {
    width: "30%",
    marginBottom: "5%",
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      marginTop: "30%",
      width: '80%'
    }
  },
  imageLogo: {
    width: "30%",
    marginBottom: "5%",
    ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
      width: '50%',
      margin: "auto 23%"
    }
  }
}));

export default function ShowFormView({match}) {
  useFirestoreConnect([{collection: 'forms', doc: match.params.id}]);
  const form = useSelector(
    ({firestore: {data}}) => data.forms && data.forms[match.params.id]
  );
  const auth = useSelector(state => state.firebase.auth);
  const [quiz, setQuiz] = useState(null);
  const [answered, setAnswered] = useState(false);
  const classes = useStyles();
  if (!isLoaded(form)) {
    return <Loader/>;
  }

  const onChangeAnswer = (index, type, value, answerIndex) => {
    const formCopy = quiz ? [...quiz] : [...form.form];

    if (type == 'multipleQuestion' || type == 'twoTextInput') {
      const answerCopy = quiz ? [...quiz[index].answer] : [...formCopy[index].answer];
      answerCopy[answerIndex] = value;
      formCopy[index] = {...formCopy[index], answer: answerCopy};
    }
    else {
      formCopy[index] = {...formCopy[index], answer: value};
    }
    setQuiz(formCopy);
  }


  const getTypeOptions = (item, index) => {
    switch (item.type) {
      case 'showText':
        return <ShowText text={item.title ? item.title : 'Sin especificar'}/>
      case 'showTextTwoHeader':
        return <ShowTextTwoHeader text={item.title ? item.title : 'Sin especificar'}/>
      case 'showImage':
        return <ShowImage imageUrl={item.imageUrl
          ? item.imageUrl
          : 'https://firebasestorage.googleapis.com/v0/b/place-valid.appspot.com/o/descarga.png?alt=media&token=be26e19e-249c-43f6-be36-873a6764f737'}
        />
      case 'showMultimedia':
        return <ShowMultimedia
          audioUrl={item.audioUrl ? item.audioUrl : ''}
          videoUrl={item.videoUrl ? item.videoUrl : ''}
        />
      case 'multipleQuestion':
        return <ShowMultipleQuestion
          title={item.title ? item.title : 'Sin especificar'}
          options={item.options}
          answer={quiz ? quiz[index].answer : item.answer}
          onChange={(answer, answerIndex) => onChangeAnswer(index, item.type, answer, answerIndex)}
        />
      case 'twoOptionQuestion':
        return <ShowTwoOptionsQuestions
          title={item.title ? item.title : 'Sin especificar'}
          options={item.options}
          answer={quiz ? quiz[index].answer : 0}
          onChange={(answer) => onChangeAnswer(index, item.type, answer)}
        />
      case 'textInput':
        return <ShowTextInput
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
      case 'twoTextInput':
        return <ShowTwoTextInput
          //title={item.title ? item.title : 'Sin especificar'}
          answer={(answerIndex, answer) => onChangeAnswer(index, item.type, answer, answerIndex)}
          multiline={false}
        />
      case 'number':
        return <ShowTextInput
          type={"number"}
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
      case 'email':
        return <ShowTextInput
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
      case 'phone':
        return <ShowTextInput
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
    }
  }

  const save = () => {
//showText
    if (quiz !== null) {
      for (let i in quiz) {
        let campo = quiz[i]
        if (campo.type === "twoTextInput" || campo.type === "showTextTwoHeader") {
          console.log(campo)
        }
        else if (campo.type === "phone") {
          if (campo.answer.length < 10) {
            alert("El campo " + campo.title + " debe de contener como minimo 10 digitos");
            return;
          }
          if (campo.answer.length > 10) {
            alert("El campo " + campo.title + " debe de contener como maximo 10 digitos");
            return;
          }

        }
        else if (campo.answer === null || campo.answer === "") {
          alert("El campo " + campo.title + " no puede estar vacio");
          return;
        }
      }
    }
    else {
      alert("Se ocupa llenar los campos");
      return;
    }
    let f = new Date();
    let date = f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear() + " " + f.getHours() + ":" + f.getMinutes();

    Services.addDocument('quiz', {quiz: quiz, formId: match.params.id, createDate: firebase.firestore.Timestamp.fromDate(new Date())})
      .then(() => setAnswered(true))
      .catch((error) => console.log(error));
  }

  return (
    <>
    {
      answered
        ?
        (form.formRedirect === undefined || form.formRedirect === null || form.formRedirect === "" ?
            <Typography align="center" style={{marginTop: '15%', fontSize: 30}}>
              {form.formLogo !== undefined || form.formLogo !== null ?
                <img src={form.formLogo} className={classes.imageFinLogo}/> : ""}
              <br/>
              {form.formMessage !== undefined || form.formMessage !== null || form.formMessage !== "" ? form.formMessage : 'Gracias!'}
              <br/>
              <a href="/forms/show/70TG9Mc1DT0QBoK5Pr0L" style={{color: "#d32117"}}>Invitar a un amigo</a>
            </Typography> :
            window.location.href = form.formRedirect
        )
        :
        <Container style={{marginTop: 20, marginBottom: 80}}>
          {form.formLogo !== undefined || form.formLogo !== null ?
            <img src={form.formLogo} className={classes.imageLogo}/> : ""}
          {

            form.form.map((item, index) => (
              <Card key={index} style={{marginTop: 10, padding: 10, width: '96%'}}>
                {getTypeOptions(item, index)}
              </Card>
            ))
          }

          <Grid container direction='row' justify='flex-end'>
            <Button
              color="primary"
              style={{color: 'white', marginTop: 20, backgroundColor: 'd32117'}}
              onClick={() => save()}
            >
              {form.formButtom === undefined || form.formButtom === null || form.formButtom === "" ? "Aceptar" : form.formButtom}
            </Button>
          </Grid>
        </Container>
    }
    </>
  );
}