import React, { useState, Fragment, useRef, useEffect } from 'react';
import ViewHeader from './../../components/HeaderView';
import Alert from './../../components/Alert';
import TableIconButton from './../../components/TableIconButton';
import { Container, TextField, Button, Grid, Typography, Box, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from './../../components/Table/index';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Loader from './../../components/Loader';
import moment from 'moment-timezone';
import firebase from './../../firebase';
import { CSVLink } from "react-csv";
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogDetails from './PayrollsDetaillsDialogView';

const header = [
  {
    prop: 'folio',
    name: 'Folio'
  },
  {
    prop: 'nameRepre',
    name: 'Representante'
  },
  {
    prop: 'emailRepre',
    name: 'Correo representante'
  },
  {
    prop: 'amount',
    name: 'Monto'
  },
  {
    prop: 'concept',
    name: 'Concepto'
  }
]

export default function Payrolls() {
  const csvInstance = useRef();
  useFirestoreConnect([
    {collection:'payrolls', orderBy: ['folio']},
    {collection:'users', orderBy: ['name']},
  ]);
  const payrolls = useSelector(state => state.firestore.ordered.payrolls);
  const users = useSelector(state => state.firestore.ordered.users);
  const [dateInitial, setDataInitial] = useState(moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'));
  const [dateFinal, setDataFinal] = useState(moment().tz("America/Hermosillo").format('YYYY-MM-DDTHH:mm'));
  const [checkBox, setCheckBox] = useState(true);
  const [payrollsFiltered, setPayrollsFiltered] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState();
  const [error, setError] = useState(null);
  const [csvData, setCsvData] = useState();
  const [payroll, setPayroll] = useState();
  
  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  if (!isLoaded(payrolls) || !isLoaded(users)) {
    return <Loader />;
  }

  const onClickSearch = () => {
    if(!autocompleteValue) {
      setError('Seleccione un usuario.');
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    }

    if(!checkBox && dateInitial > dateFinal) {
      setError('La fecha inicial no puede ser despues que la final.');
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    }

    let _payrollsFiltered = [];

    if(checkBox) {
      _payrollsFiltered = payrolls.filter(payroll => payroll.nameRepre == autocompleteValue.name);
    }
    else {
      const timeInitial = firebase.firestore.Timestamp.fromDate(new Date(dateInitial));
      const timeFinal = firebase.firestore.Timestamp.fromDate(new Date(dateFinal));

      _payrollsFiltered = payrolls.filter(payroll => payroll.nameRepre == autocompleteValue.name
        && (payroll.registry >= timeInitial && payroll.registry <= timeFinal)
      );
    }

    if(!_payrollsFiltered.length) {
      setError('No se encontraron nóminas.');
      setTimeout(() => {
        setError(null);
      }, 3000);
    }

    setPayrollsFiltered(_payrollsFiltered);
  }

  const onClickDownload = () => {
    if(!payrollsFiltered.length) {
      setError('Favor de realizar una busqueda.');
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    }

    let newCsvData = [];
   
    newCsvData.push(['Brigadier']);
    newCsvData.push(['Representante: ', autocompleteValue.name]);
    newCsvData.push(['Rango de fechas: ', !checkBox ? (dateInitial + ' a ' + dateFinal) : 'Sin especificar.']);
    newCsvData.push([]);
    newCsvData.push(['Folio', 'Representante', 'Correo representante', 'Monto', 'Concepto', 'Fecha de pago']);

    payrollsFiltered.forEach(payroll => {
      newCsvData.push([
        payroll.folio, 
        payroll.nameRepre,
        payroll.emailRepre,
        payroll.amount ? payroll.amount : 'Sin pago.',
        payroll.concept,
        payroll.paymentDate ? moment(Date.parse(payroll.paymentDate.toDate())).format('YYYY-MM-DD HH:mm') : 'Sin fecha de pago.'
      ]);
    });

    return newCsvData;
  }

  return (
    <>
      <ViewHeader Text='Nóminas' /> 
      <Container  style={{marginTop: 20, marginBottom: 50}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                setAutocompleteValue(newValue);
              }}
              options={users}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => 
                <TextField {...params} label={'Representantes'} 
                  variant="filled" 
                  fullWidth 
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField 
              variant="filled"  fullWidth type="datetime-local" 
              InputLabelProps={{shrink: true}}
              disabled={checkBox}
              onChange={(event) => setDataInitial(event.target.value)} value={dateInitial}  
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField 
              variant="filled"  fullWidth type="datetime-local" 
              InputLabelProps={{shrink: true}}
              disabled={checkBox}
              onChange={(event) => setDataFinal(event.target.value)} value={dateFinal}  
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography align='center'>Sin rango de fechas</Typography>
            <Box display="flex" justifyContent="center">
                <Checkbox value={checkBox} defaultChecked={checkBox} onChange={() => setCheckBox(!checkBox)} ></Checkbox>
            </Box>
          </Grid>
          <Grid item xs={6} md={1}>
            <Button variant='contained' fullWidth onClick={onClickSearch} style={{backgroundColor: '#ffc107', color: 'white'}}>
              Buscar
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
          <Fragment>
            <div
              onClick={async () => {
                const newCsvData = await onClickDownload();
                setCsvData(newCsvData);
              }}
            >
              <Button variant='contained' fullWidth startIcon={<GetAppIcon />}  
                onClick={onClickDownload} style={{backgroundColor: '#ffc107', color: 'white'}}
              >
                Descargar XLS
              </Button>
            </div>
            {csvData ?
              <CSVLink
                filename={"Visitas.csv"}
                data={csvData}
                ref={csvInstance}
              />
            : undefined }
            </Fragment>
          </Grid>
        </Grid>
        <Table
          filter={['concept', 'email']}
          paginated
          header={header}
          data={payrollsFiltered}
          extraRows={[
            {
              prop: 'paymentDate',
              name: 'Fecha de pago',
              cell: row => (
              <>{row.paymentDate ? moment(Date.parse(row.paymentDate.toDate())).format('YYYY-MM-DD HH:mm') : 'Sin fecha de pago'}</> 
              )
            }, 
            {
              prop: 'show',
              name: 'Ver',
              cell: row => (
                <TableIconButton
                  onClick={() => setPayroll(row)}
                  icon={VisibilityIcon}/>
              )
            },
          ]}
        />
      </Container>
      <Alert
        open={Boolean(error)}
        onClose={() => setError(null)}
        message={error}
        severity="error" />
      <DialogDetails
        open={Boolean(payroll)}
        onAccept={() => setPayroll()}
        payroll={payroll}
      />
    </>
  );
}
