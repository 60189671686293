import React, {useState} from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';
import ShowTextInput from './../../forms/typeOptios/ShowTextInput';

export default function ShowTwoTextInput({title, answer, multiline }) {

  return(

    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextField
        variant="outlined"
        onChange={(event) => answer(0, event.target.value)}
        multiline
        rows={multiline ? 4 : 0}
        fullWidth
      />
      </Grid>
      <Grid item xs={8}>
        <TextField
        variant="outlined"
        onChange={(event) => answer(1, event.target.value)}
        multiline
        rows={multiline ? 4 : 0}
        fullWidth
      />
      </Grid>
    </Grid>

  )
}