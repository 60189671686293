import React, {useState} from 'react';
import Table from './../../components/Table/index';
import Container from './../../components/Container';
import ViewHeader from './../../components/HeaderView';
import Loader from './../../components/Loader';
import TableIconButton from './../../components/TableIconButton';
import DeleteDialog from './../../components/DeleteDialog';
import {Link} from 'react-router-dom';
import FormsDialog from './FormsDialogView';
import {isLoaded, useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {Box, IconButton} from '@material-ui/core';
import {Add as AddIcon, Edit as EditIcon, Visibility as VisibilityIcon} from '@material-ui/icons';
import Services from './../../services';
import getCroppedImg from './cropImage';
import ContactsIcon from '@material-ui/icons/Contacts';

const header = [
  {
    prop: 'formName',
    name: 'Nombre',
  },
  {
    prop: 'formMessage',
    name: 'Mensaje despues del envío'
  },
  {
    prop: 'formRedirect',
    name: 'Redirecciona a:'
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function FormsView(props) {
  const INTERACTIVE = {
    'location': false,
    'image': false,
    'scanner': false,
    'firma': false
  }


  useFirestoreConnect([{
    collection: 'forms',
    orderBy: ['formName'],
    where: [
      ['userId', '==', props.uid]
    ]
  }]);

  const forms = useSelector(state => state.firestore.ordered.forms);
  const auth = useSelector(state => state.firebase.auth);
  const [mode, setMode] = useState();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState([]);
  const [formName, setFormName] = useState('');
  const [formRedirect, setFormRedirect] = useState('');
  const [formButtom, setFormButtom] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [interactive, setInteractive] = useState(INTERACTIVE);
  const [loading, setLoading] = useState(false);
  const [formId, setFormId] = useState();
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [formIdDelete, setFormIdDelete] = useState(false);

  if (!isLoaded(forms) || !isLoaded(auth)) {
    return <Loader/>;
  }

  const openDialog = (row, mode) => {
    if (row) {
      let _form = [];

      row.form.forEach((item, index) => {
        _form.push({...item, id: index.toString()});

        if (_form[index].type == 'showImage') {
          _form[index].zoom = 1;
          _form[index].crop = {x: 0, y: 0}
          _form[index].croppedAreaPixels = null;
        }
      });

      setFormName(row.formName);
      setFormRedirect(row.formRedirect);
      setFormButtom(row.formButtom);
      setFormMessage(row.formMessage);
      setForm(_form);
      setInteractive({...row.interactive});
      setFormId(row.id);
    }
    setMode(mode);
    setOpen(true);
  }

  const onCancel = () => {
    setForm([]);
    setFormName('');
    setFormRedirect('');
    setFormButtom('');
    setFormMessage('');
    setInteractive(INTERACTIVE);
    setOpen(false);
    setFormId();
    setFilesToDelete([]);
  }

  const onChange = (type, value, index, key, optionIndex) => {
    const formCopy = [...form];

    if (type == 'showText' || type == 'textInput' || type == 'number' || type == 'email' || type == 'phone') {
      formCopy[index].title = value;
    }

    if (type == 'showImage') {
      formCopy[index].imageUrl = URL.createObjectURL(value);
      formCopy[index].imagePath = value;
    }

    if (type == 'showMultimedia') {
      if (value.type == 'video/mp4') {
        formCopy[index].videoUrl = URL.createObjectURL(value);
        formCopy[index].videoPath = value;

        if (formCopy[index].audioUrl) {
          delete formCopy[index].audioUrl;
          delete formCopy[index].audioPath;
        }
      }

      if (value.type == 'audio/mpeg') {
        formCopy[index].audioUrl = URL.createObjectURL(value);
        formCopy[index].audioPath = value;

        if (formCopy[index].videoUrl) {
          delete formCopy[index].videoUrl;
          delete formCopy[index].videoPath;
        }
      }
    }

    if (type == 'multipleQuestion' || type == 'twoOptionQuestion') {
      if (key == 'title') {
        formCopy[index].title = value;
      }
      else {
        formCopy[index].options[optionIndex] = value;
      }
    }

    setForm(formCopy);
  }

  const addItem = type => {
    const formCopy = [...form];

    if (type == 'showImage') {
      formCopy.push({
        id: formCopy.length.toString(),
        type: type,
        answer: '',
        zoom: 1,
        crop: {x: 0, y: 0},
        croppedAreaPixels: null
      });
    }
    else if (type === 'multipleQuestion') {
      formCopy.push({id: formCopy.length.toString(), type: type, options: ['', ''], answer: [false, false]});
    }
    else if (type === 'twoOptionQuestion') {
      formCopy.push({id: formCopy.length.toString(), type: type, options: ['', ''], answer: 0});
    }
    else if (type === 'twoTextInput') {
      formCopy.push({id: formCopy.length.toString(), type: type, answer: ['', '']});
    }
    else {
      formCopy.push({id: formCopy.length.toString(), type: type, answer: ''});
    }

    setForm(formCopy);
  }

  const onDelete = index => {
    const formCopy = [...form];
    const filesToDeleteCopy = [...filesToDelete];

    if (formCopy[index].imageUrl && formCopy[index].imageUrl.includes('https://firebasestorage.googleapis.com')) {
      filesToDeleteCopy.push(formCopy[index].imagePath);
    }
    if (formCopy[index].audioUrl && formCopy[index].audioUrl.includes('https://firebasestorage.googleapis.com')) {
      filesToDeleteCopy.push(formCopy[index].audioPath);
    }
    if (formCopy[index].videoUrl && formCopy[index].videoUrl.includes('https://firebasestorage.googleapis.com')) {
      filesToDeleteCopy.push(formCopy[index].videoPath);
    }

    formCopy.splice(index, 1);

    setFilesToDelete(filesToDeleteCopy);
    setForm(formCopy);
  }

  const onDeleteOption = (index, indexOption) => {
    const formCopy = [...form];

    formCopy[index].options.splice(indexOption, 1);
    formCopy[index].answer.splice(indexOption, 1);

    setForm(formCopy);
  }

  const onAddOption = index => {
    const formCopy = [...form];

    formCopy[index].options.push('');
    formCopy[index].answer.push(false);

    setForm(formCopy);
  }

  const onChangeFormRedirect = value => {
    setFormRedirect(value);
  }

  const onChangeFormButtom = value => {
    setFormButtom(value);
  }

  const onChangeFormMessage = value => {
    setFormMessage(value);
  }

  const onChangeFormName = value => {
    setFormName(value);
  }

  const changeInteractive = (key, value) => {
    setInteractive({...interactive, [key]: value})
  }

  const addForm = async () => {
    setLoading(true);
    const formCopy = [...form];
    const item = form.find(item => item.type == 'showImage' || item.type == 'showMultimedia');

    try {
      if (item) {
        for (let index = 0; index < form.length; index++) {
          if (form[index].type == 'showImage' || form[index].type == 'showMultimedia') {
            const now = Date.now().toString();

            if (form[index].imageUrl) {
              const imageCropped = await showCroppedImages(form[index].imageUrl, form[index].croppedAreaPixels);
              const imageUrl = await Services.uploadFile('images/' + now + auth.uid.toString(), imageCropped);
              formCopy[index].imageUrl = imageUrl;
              formCopy[index].imagePath = 'images/' + now + auth.uid.toString();
            }
            if (form[index].audioUrl) {
              const audioUrl = await Services.uploadFile('audios/' + now + auth.uid.toString(), form[index].audioPath);
              formCopy[index].audioUrl = audioUrl;
              formCopy[index].audioPath = 'audios/' + now + auth.uid.toString();
            }
            if (form[index].videoUrl) {
              const videoUrl = await Services.uploadFile('videos/' + now + auth.uid.toString(), form[index].videoPath);
              formCopy[index].videoUrl = videoUrl;
              formCopy[index].videoPath = 'videos/' + now + auth.uid.toString();
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      return;
    }

    const textMensaje = {}

    const data = {
      formName: formName,
      formRedirect: formRedirect !== undefined ? formRedirect : null,
      formButtom: formButtom !== undefined ? formButtom : null,
      formMessage: formMessage !== undefined ? formMessage : null,
      form: formCopy,
      interactive: interactive,
    };

    Services.addDocument('forms', data)
      .then(() => {
        setOpen(false);
        setInteractive(INTERACTIVE);
        setFormName('');
        setFormRedirect('');
        setFormButtom('');
        setFormMessage('');
        setForm([]);
        setLoading(false);
      })
      .catch((error) => console.log("Add Form", error))
  }

  const updateForm = async () => {
    setLoading(true);

    const formCopy = [...form];
    const item = form.find(item => item.type == 'showImage' || item.type == 'showMultimedia');

    if (filesToDelete.length) {
      for (let index = 0; index < filesToDelete.length; index++) {
        await Services.deleteFile(filesToDelete[index]);
      }
    }

    try {
      if (item) {
        const formIndex = forms.map(item => (item.id)).indexOf(formId);

        for (let index = 0; index < form.length; index++) {
          if (form[index].type == 'showImage' || form[index].type == 'showMultimedia') {
            const now = Date.now().toString();

            if (form[index].imageUrl) {
              const imageCropped = await showCroppedImages(form[index].imageUrl, form[index].croppedAreaPixels);

              if (forms[formIndex].form[index] && forms[formIndex].form[index].imagePath) {
                await Services.deleteFile(forms[formIndex].form[index].imagePath);
              }

              const imageUrl = await Services.uploadFile('images/' + now + auth.uid.toString(), imageCropped);
              formCopy[index].imageUrl = imageUrl;
              formCopy[index].imagePath = 'images/' + now + auth.uid.toString();
            }
            if (form[index].audioUrl && !form[index].audioUrl.includes('https://firebasestorage.googleapis.com')) {
              if (forms[formIndex].form[index] && forms[formIndex].form[index].audioPath) {
                await Services.deleteFile(forms[formIndex].form[index].audioPath);
              }

              const audioUrl = await Services.uploadFile('audios/' + now + auth.uid.toString(), form[index].audioPath);
              formCopy[index].audioUrl = audioUrl;
              formCopy[index].audioPath = 'audios/' + now + auth.uid.toString();
            }
            if (form[index].videoUrl && !form[index].videoUrl.includes('https://firebasestorage.googleapis.com')) {
              if (forms[formIndex].form[index] && forms[formIndex].form[index].videoPath) {
                await Services.deleteFile(forms[formIndex].form[index].videoPath);
              }

              const videoUrl = await Services.uploadFile('videos/' + now + auth.uid.toString(), form[index].videoPath);
              formCopy[index].videoUrl = videoUrl;
              formCopy[index].videoPath = 'videos/' + now + auth.uid.toString();
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    const data = {
      formName: formName,
      formRedirect: formRedirect !== undefined ? formRedirect : null,
      formButtom: formButtom !== undefined ? formButtom : null,
      formMessage: formMessage !== undefined ? formMessage : null,
      form: formCopy,
      interactive: interactive,
    };

    console.log(formMessage);

    Services.updateDocument('forms', formId, data)
      .then(() => {
        setOpen(false);
        setInteractive(INTERACTIVE);
        setFormName('');
        setFormRedirect('');
        setFormButtom('');
        setFormMessage('');
        setForm([]);
        setFormId();
        setFilesToDelete([]);
        setLoading(false);
      })
      .catch((error) => console.log("update error", error))
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      form,
      result.source.index,
      result.destination.index
    );

    setForm(items);
  }

  const handleDelete = async () => {
    const index = forms.map(item => (item.id)).indexOf(formIdDelete);

    for (let i = 0; i < forms[index].form.length; i++) {
      if (forms[index].form[i].type == 'showImage') {
        await Services.deleteFile(forms[index].form[i].imagePath);
      }
      if (forms[index].form[i].type == 'showMultimedia') {
        if (forms[index].form[i].audioPath) {
          await Services.deleteFile(forms[index].form[i].audioPath);
        }
        else {
          await Services.deleteFile(forms[index].form[i].videoPath);
        }
      }
    }

    Services.deleteDocument('forms', formIdDelete).then(() => {
      setFormIdDelete();
    });
  }

  const onChangeDragDisabled = index => {
    let formCopy = [...form];

    if (formCopy[index].isDragDisabled) {
      formCopy[index].isDragDisabled = !formCopy[index].isDragDisabled
    }
    else {
      formCopy[index].isDragDisabled = true;
    }

    setForm(formCopy);
  }

  const onCropChange = (index, crop) => {
    const formCopy = [...form];
    formCopy[index].crop = crop;

    setForm(formCopy);
  }

  const onCropComplete = (index, croppedArea, croppedAreaPixels) => {
    const formCopy = [...form];
    formCopy[index].croppedAreaPixels = croppedAreaPixels;

    setForm(formCopy);
  }

  const onZoomChange = (index, zoom) => {
    const formCopy = [...form];
    formCopy[index].zoom = zoom;

    setForm(formCopy);
  }

  const showCroppedImages = async (image, croppedAreaPixels) => {
    try {
      return await getCroppedImg(
        image,
        croppedAreaPixels,
        0
      );

    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return (
    <>
    <ViewHeader Text='Capturas'/>
    <Container>
      <div style={{width: '100%'}}>
        <Box display="flex" flexDirection="row-reverse">
          <Box p={1}>
            <IconButton style={{backgroundColor: '#4caf50'}} onClick={() => openDialog(null, 'add')}>
              <AddIcon style={{color: 'white'}}/>
            </IconButton>
          </Box>
        </Box>
      </div>
      <Table
        header={header}
        data={forms}
        extraRows={[
          /*{
            prop: 'delete',
            name: 'Eliminar',
            cell: row => (
              <IconButton style={{backgroundColor: '#ffac33'}} onClick={() => setFormIdDelete(row.id)}>
                <TableIconButton
                  color={'white'}
                  icon={DeleteIcon}
                />
              </IconButton>
            )
          },*/
          {
            prop: 'show',
            name: 'Ver formulario',
            cell: row => (
              <Link to={"/forms/show/" + row.id}>
                <IconButton style={{backgroundColor: '#09a000'}}>
                  <TableIconButton
                    color={'white'}
                    icon={VisibilityIcon}/>
                </IconButton>
              </Link>
            )
          },
          {
            prop: 'actions',
            name: 'Modificar',
            cell: row => (
              <IconButton style={{backgroundColor: '#ffcd38'}} onClick={() => openDialog(row, 'update')}>
                <TableIconButton
                  color={'white'}
                  icon={EditIcon}/>
              </IconButton>
            )
          },
          {
            prop: 'form-resp',
            name: 'Jovenes inscritos',
            cell: row => (
              <Link to={"/forms/answers/" + row.id}>

                <IconButton style={{backgroundColor: '#d32117'}}>
                  <TableIconButton
                    color={'white'}
                    icon={ContactsIcon}/>
                </IconButton>
              </Link>
            )
          },
        ]}
      />
    </Container>
    <FormsDialog
      open={open}
      form={form}
      mode={mode}
      onCancel={onCancel}
      onAccept={(mode) => mode == 'add' ? addForm() : updateForm()}
      onChange={onChange}
      addItem={addItem}
      onDelete={onDelete}
      onDeleteOption={onDeleteOption}
      onAddOption={onAddOption}
      onChangeFormName={onChangeFormName}
      onChangeFormRedirect={onChangeFormRedirect}
      onChangeFormButtom={onChangeFormButtom}
      onChangeFormMessage={onChangeFormMessage}
      formName={formName}
      formRedirect={formRedirect}
      formButtom={formButtom}
      formMessage={formMessage}
      interactive={interactive}
      changeInteractive={changeInteractive}
      loading={loading}
      onDragEnd={onDragEnd}
      onChangeDragDisabled={onChangeDragDisabled}
      onCropComplete={onCropComplete}
      onCropChange={onCropChange}
      onZoomChange={onZoomChange}
    />
    <DeleteDialog
      open={Boolean(formIdDelete)}
      onAccept={() => handleDelete()}
      onCancel={() => setFormIdDelete()}
    />
    </>
  );
}