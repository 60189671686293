import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
  }
}));

const TableIconButton = ({color, icon: Icon, ...rest }) => {
  const classes = useStyles();
  return (
    <Icon
      {...rest}
      style={{color: color ? color : 'gray'}}
      className={classes.root} />
  );
};

export default TableIconButton;