import React, {useState, useCallback} from 'react';
import MessageIcon from '@material-ui/icons/Message';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import {
  Button, Grid, IconButton, Card, Typography, CircularProgress,
  Dialog, DialogActions, MenuItem, FormHelperText, Box,
  DialogContent, Select, InputLabel, InputAdornment,
  DialogTitle, FormControl, TextField, Switch
} from '@material-ui/core';
import {Delete as DeleteIcon, Add as AddIcon} from '@material-ui/icons';
import ReactAudioPlayer from 'react-audio-player';
import {Player} from 'video-react';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import HdrWeakIcon from '@material-ui/icons/HdrWeak';
import RoomIcon from '@material-ui/icons/Room';
import ImageIcon from '@material-ui/icons/Image';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CreateIcon from '@material-ui/icons/Create';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import DialpadIcon from '@material-ui/icons/Dialpad';
import Slide from '@material-ui/core/Slide';
import "video-react/dist/video-react.css";
import Container from './../../components/Container';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Cropper from 'react-easy-crop';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormsDialogView({
      open, form, mode, onCancel, onAccept, onChange, addItem, onDelete, onDeleteOption, onAddOption,
      onChangeFormName, onChangeFormMessage, onChangeFormRedirect, onChangeFormButtom, formMessage,
      formName, formRedirect, formButtom, interactive, changeInteractive, loading, onDragEnd,
      onChangeDragDisabled, onCropComplete, onCropChange, onZoomChange
                                        }) {

  const [type, setType] = useState('');
  const [invalidFields, setInvalidFields] = useState([]);
  const [rotation, setRotation] = useState(0);

  const handleCancel = () => {
    setType('');
    onCancel();
  }

  const handleChangeType = event => {
    setType(event.target.value);
  }

  const onClickAddItem = () => {
    if (type) {
      addItem(type);
      setType('');
    }
  };

  const handleBeforeSubmit = async () => {
    const invalidFields = [];

    if (!formName) {
      invalidFields.push('formName');
    }

    if (!form.length) {
      invalidFields.push('formEmpty');
    }

    setInvalidFields(invalidFields);

    if (!invalidFields.length) {
      setType('');
      onAccept(mode);
    }
  }

  return (
    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle>{mode == 'update' ? 'Editar' : 'Agregar'}</DialogTitle>
      <DialogContent>
        <Container>
          <Grid container spacing={1}>

            <Grid item md={12} style={{margin:"10px"}}>
              <TextField
                variant="outlined"
                onChange={(event) => onChangeFormName(event.target.value)}
                label={'Nombre del formulario'}
                fullWidth
                required
                value={formName}
                helperText="Favor de escribir el nombre del formulario."
                error={invalidFields.includes('formName')}
              />
            </Grid>

            <Grid item md={12} style={{margin:"10px"}}>
              <TextField
                variant="outlined"
                onChange={(event) => onChangeFormMessage(event.target.value)}
                label={'Mensage de Término'}
                fullWidth
                required
                value={formMessage}
                multiline={true}
              />
            </Grid>

            <Grid item md={12} style={{margin:"10px"}}>
              <TextField
                variant="outlined"
                onChange={(event) => onChangeFormRedirect(event.target.value)}
                label={'Url de redireccion del formulario'}
                fullWidth
                required
                value={formRedirect}
              />
            </Grid>

            <Grid item md={12} style={{margin:"10px"}}>
              <TextField
                variant="outlined"
                onChange={(event) => onChangeFormButtom(event.target.value)}
                label={'Texto del boton'}
                fullWidth
                required
                value={formButtom}
              />
            </Grid>

            <Card style={{width: '100%', marginTop: 10}}>
              <Grid container style={{padding: 10}}>
                <Grid item md={1} xs={1}>
                  <RoomIcon style={{marginTop: 8}}/>
                </Grid>
                <Grid item md={10} xs={10}>
                  <Typography style={{marginTop: 10}}>Localización</Typography>
                </Grid>
                <Grid item md={1} xs={1}>
                  <Switch
                    checked={interactive.location}
                    onChange={() => changeInteractive('location', !interactive.location)}
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <ImageIcon style={{marginTop: 8}}/>
                </Grid>
                <Grid item md={10} xs={10}>
                  <Typography style={{marginTop: 10}}>Imagen</Typography>
                </Grid>
                <Grid item md={1} xs={1}>
                  <Switch
                    checked={interactive.image}
                    onChange={() => changeInteractive('image', !interactive.image)}
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <CropFreeIcon style={{marginTop: 8}}/>
                </Grid>
                <Grid item md={10} xs={10}>
                  <Typography style={{marginTop: 10}}>Escáner código de barras y QR</Typography>
                </Grid>
                <Grid item md={1} xs={1}>
                  <Switch
                    checked={interactive.scanner}
                    onChange={() => changeInteractive('scanner', (!interactive.scanner))}
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <CreateIcon style={{marginTop: 8}}/>
                </Grid>
                <Grid item md={10} xs={10}>
                  <Typography style={{marginTop: 10}}>Firma</Typography>
                </Grid>
                <Grid item md={1} xs={1}>
                  <Switch
                    checked={interactive.firma}
                    onChange={() => changeInteractive('firma', !interactive.firma)}
                  />
                </Grid>
              </Grid>
            </Card>
            <Grid item md={11} xs={11}>
              <FormControl fullWidth variant="outlined" required
                           style={{marginTop: 10}} error={invalidFields.includes('formEmpty')}>
                <InputLabel>Tipo</InputLabel>
                <Select
                  value={type}
                  onChange={handleChangeType}
                  name="type"
                  label="Tipo"
                >
                  <MenuItem value={'showText'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Mostrar texto
                        </Box>
                        <Box pr={1}>
                          <MessageIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value={'showImage'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Visualizador de imagen
                        </Box>
                        <Box pr={1}>
                          <InsertPhotoIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value={'showMultimedia'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Visualizador de multimedia
                        </Box>
                        <Box pr={1}>
                          <VisibilityIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value={'multipleQuestion'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Opción multiple
                        </Box>
                        <Box pr={1}>
                          <FormatListBulletedIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value={'twoOptionQuestion'}>
                    <Grid container spacing={1}>
                      <div style={{width: '100%'}}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                          </Box>
                          <Box pr={1}>
                            Dos opciones
                          </Box>
                          <Box pr={1}>
                            <HdrWeakIcon/>
                          </Box>
                        </Box>
                      </div>
                    </Grid>
                  </MenuItem>
                  <MenuItem value={'twoTextInput'}>
                    <Grid container spacing={1}>
                      <div style={{width: '100%'}}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                          </Box>
                          <Box pr={1}>
                            Dos cajas de texto
                          </Box>
                          <Box pr={1}>
                            <HdrWeakIcon/>
                          </Box>
                        </Box>
                      </div>
                    </Grid>
                  </MenuItem>
                  <MenuItem value={'textInput'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Entrada de texto
                        </Box>
                        <Box pr={1}>
                          <KeyboardIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value={'number'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Número
                        </Box>
                        <Box pr={1}>
                          <DialpadIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value={'email'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Correo
                        </Box>
                        <Box pr={1}>
                          <EmailIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value={'phone'}>
                    <div style={{width: '100%'}}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                        </Box>
                        <Box pr={1}>
                          Teléfono
                        </Box>
                        <Box pr={1}>
                          <PhoneIcon/>
                        </Box>
                      </Box>
                    </div>
                  </MenuItem>
                </Select>
                <FormHelperText>Formulario vacio.</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={1} xs={1}>
              <IconButton style={{backgroundColor: '#4caf50', marginTop: 13}}
                          onClick={onClickAddItem}>
                <AddIcon style={{color: 'white'}}/>
              </IconButton>
            </Grid>
            <br/>
            {
              form.length
                ?
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        style={{width: '100%'}}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {form.map((item, index) => (
                          <Draggable isDragDisabled={item.isDragDisabled} key={item.id} draggableId={item.id}
                                     index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card style={{marginBottom: 15}}>
                                  <Grid container style={{marginTop: 15, padding: 10}} spacing={2}>
                                    <Grid item md={10} xs={10}>
                                      {
                                        item.type == 'showText'
                                          ?
                                          <>
                                          <Typography>Mostrar texto</Typography>
                                          <TextField
                                            variant="outlined"
                                            onChange={(event) => onChange(item.type, event.target.value, index)}
                                            multiline
                                            rows={4}
                                            fullWidth
                                            value={item.title ? item.title : ''}
                                          />
                                          </>
                                          :
                                          item.type == 'showImage'
                                            ?
                                            <div
                                              className={null}>
                                              <Grid container spacing={2}>
                                                <Grid item md={1} xs={1}/>

                                                <Grid item md={3} xs={3}>
                                                  <Typography>Visualizador de imagen</Typography>
                                                  <Button
                                                    style={{backgroundColor: '#4caf50', color: 'white', marginTop: 20}}
                                                    component="label">
                                                    IMAGEN
                                                    <input type="file" accept="image/x-png,image/gif,image/jpeg"
                                                           style={{display: "none"}} onChange={e => {
                                                      onChange(item.type, e.target.files[0], index)
                                                    }}
                                                    />
                                                  </Button>
                                                </Grid>
                                                <Grid item md={1} xs={1}/>
                                                <Grid item md={5} xs={5}>
                                                  <div className={null}
                                                       style={{position: 'relative', width: '100%', height: 250}}>
                                                    <Cropper
                                                      image={item.imageUrl ? item.imageUrl : 'https://firebasestorage.googleapis.com/v0/b/place-valid.appspot.com/o/descarga.png?alt=media&token=be26e19e-249c-43f6-be36-873a6764f737'}
                                                      crop={item.crop}
                                                      zoom={item.zoom}
                                                      aspect={4 / 3}
                                                      onCropChange={(crop) => onCropChange(index, crop)}
                                                      onRotationChange={setRotation}
                                                      onCropComplete={(croppedArea, croppedAreaPixels) => onCropComplete(index, croppedArea, croppedAreaPixels)}
                                                      onZoomChange={(zoom) => onZoomChange(index, zoom)}
                                                    />
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </div>
                                            :
                                            item.type == 'showMultimedia'
                                              ?
                                              <>
                                              <Grid container spacing={2}>
                                                <Grid item md={1} xs={1}/>
                                                <Grid item md={4} xs={4}>
                                                  <Typography>Visualizador de multimedia</Typography>
                                                  <Button
                                                    style={{backgroundColor: '#4caf50', color: 'white', marginTop: 20}}
                                                    component="label">
                                                    AUDIO O VIDEO
                                                    <input type="file"
                                                           accept=".mp3,audio/*,video/mp4,video/x-m4v,video/*"
                                                           style={{display: "none"}} onChange={e => {
                                                      onChange(item.type, e.target.files[0], index)
                                                    }}
                                                    />
                                                  </Button>
                                                </Grid>
                                                <Grid item md={5} xs={5}>
                                                  {
                                                    item.audioUrl
                                                      ?
                                                      <Card style={{backgroundColor: '#f1f3f4', width: '300px'}}>
                                                        <ReactAudioPlayer
                                                          src={item.audioUrl}
                                                          controls
                                                        />
                                                      </Card>
                                                      :
                                                      item.videoUrl
                                                        ?
                                                        <Card style={{width: '100%'}}>
                                                          <Player>
                                                            <source src={item.videoUrl}/>
                                                          </Player>
                                                        </Card>
                                                        :
                                                        null
                                                  }
                                                </Grid>
                                              </Grid>
                                              </>
                                              :
                                              item.type == 'multipleQuestion'
                                                ?
                                                <>
                                                <Typography>Opción multiple</Typography>
                                                <TextField
                                                  variant="outlined"
                                                  onChange={(event) => onChange(item.type, event.target.value, index, 'title')}
                                                  multiline
                                                  rows={4}
                                                  fullWidth
                                                  value={item.title ? item.title : ''}
                                                />
                                                {
                                                  item.options.map((_item, _index) => (
                                                    <div key={_index} style={{marginTop: 10}}>
                                                      <TextField
                                                        variant="outlined"
                                                        onChange={(event) => onChange(item.type, event.target.value, index, 'options', _index)}
                                                        fullWidth
                                                        value={_item}
                                                        label={'Respuesta ' + (_index + 1)}
                                                        InputProps={{
                                                          endAdornment: _index > 1
                                                            ?
                                                            (
                                                              <InputAdornment position="end">
                                                                <IconButton
                                                                  style={{backgroundColor: '#ffac33'}}
                                                                  onClick={() => onDeleteOption(index, _index)}
                                                                  edge="end"
                                                                >
                                                                  <DeleteIcon style={{color: 'white'}}/>
                                                                </IconButton>
                                                              </InputAdornment>
                                                            )
                                                            :
                                                            null
                                                        }}
                                                      />
                                                    </div>
                                                  ))
                                                }
                                                <Typography style={{cursor: 'pointer', color: '#4caf50'}}
                                                            onClick={() => onAddOption(index)}
                                                >
                                                  <u><b>+</b>Agregar respuesta</u>
                                                </Typography>
                                                </>
                                                :
                                                item.type == 'twoOptionQuestion'
                                                  ?
                                                  <>
                                                  <Typography>Dos opciones</Typography>
                                                  <TextField
                                                    variant="outlined"
                                                    onChange={(event) => onChange(item.type, event.target.value, index, 'title')}
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    value={item.title ? item.title : ''}
                                                  />
                                                  {
                                                    item.options.map((_item, _index) => (
                                                      <div key={_index} style={{marginTop: 10}}>
                                                        <TextField
                                                          variant="outlined"
                                                          onChange={(event) => onChange(item.type, event.target.value, index, 'options', _index)}
                                                          fullWidth
                                                          value={_item}
                                                          label={'Respuesta ' + (_index + 1)}
                                                        />
                                                      </div>
                                                    ))
                                                  }
                                                  </>
                                                  :
                                                  item.type == 'textInput'
                                                    ?
                                                    <>
                                                    <Typography>Entrada de texto</Typography>
                                                    <TextField
                                                      variant="outlined"
                                                      onChange={(event) => onChange(item.type, event.target.value, index, 'title')}
                                                      multiline
                                                      rows={4}
                                                      fullWidth
                                                      value={item.title ? item.title : ''}
                                                    />
                                                    </>
                                                    :
                                                    item.type == 'number'
                                                      ?
                                                      <>
                                                      <Typography>Número</Typography>
                                                      <TextField
                                                        variant="outlined"
                                                        onChange={(event) => onChange(item.type, event.target.value, index, 'title')}
                                                        fullWidth
                                                        value={item.title ? item.title : ''}
                                                      />
                                                      </>
                                                      :
                                                      item.type == 'email'
                                                        ?
                                                        <>
                                                        <Typography>Email</Typography>
                                                        <TextField
                                                          type="email"
                                                          variant="outlined"
                                                          onChange={(event) => onChange(item.type, event.target.value, index, 'title')}
                                                          fullWidth
                                                          value={item.title ? item.title : ''}
                                                        />
                                                        </>
                                                        :
                                                        item.type == 'phone'
                                                          ?
                                                          <>
                                                          <Typography>Teléfono</Typography>
                                                          <TextField
                                                            variant="outlined"
                                                            onChange={(event) => onChange(item.type, event.target.value, index, 'title')}
                                                            fullWidth
                                                            value={item.title ? item.title : ''}
                                                          />
                                                          </>
                                                          :
                                                          null
                                      }
                                    </ Grid>
                                    <Grid item md={2} xs={2}>
                                      <IconButton
                                        style={{backgroundColor: '#ffac33'}}
                                        onClick={() => {
                                          onDelete(index)
                                        }}>
                                        <DeleteIcon style={{color: 'white'}}/>
                                      </IconButton>
                                      {
                                        item.type == 'showImage'
                                          ?
                                          <>
                                          <Typography style={{marginTop: 15}}>Editar imagen</Typography>
                                          <Switch
                                            checked={item.isDragDisabled ? item.isDragDisabled : false}
                                            onChange={() => {
                                              onChangeDragDisabled(index)
                                            }}
                                          />
                                          </>
                                          :
                                          null
                                      }

                                    </Grid>
                                  </Grid>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                :
                null
            }
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions style={{backgroundColor: '#b1a8a8'}}>
        <Button onClick={handleCancel} style={{color: '#4caf50', backgroundColor: 'white'}} color="primary">
          Cancelar
        </Button>
        {
          !loading
            ?
            <Button onClick={handleBeforeSubmit} style={{color: '4caf50', backgroundColor: 'white'}} autoFocus>
              Aceptar
            </Button>
            :
            <CircularProgress style={{color: '#4caf50'}}/>
        }
      </DialogActions>
    </Dialog>
  );
}