import React from 'react';
import { 
  Box, Button,
  Dialog, DialogActions,
  DialogContent, DialogContentText, 
  DialogTitle
} from '@material-ui/core';

const DeleteDialog = ({ open, onClose, onCancel, onAccept }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description">
      <DialogTitle id="delete-dialog-title">¡Advertencia!</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
        ¿Seguro que desea eliminar este registro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={onCancel} style={{color: '4caf50'}} color="primary">
          Cancelar
          </Button>
          <Button onClick={onAccept} style={{color: '4caf50'}} autoFocus>
          Aceptar
          </Button>
      </DialogActions>
  </Dialog>
  );
};

export default DeleteDialog;