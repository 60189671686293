import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyC6d7UnH0i-geycPHOZVUThPI69AuuMHsM",
    authDomain: "jovenes-4t.firebaseapp.com",
    projectId: "jovenes-4t",
    storageBucket: "jovenes-4t.appspot.com",
    messagingSenderId: "798861595867",
    appId: "1:798861595867:web:fbf45cb0ead729f935e8fe"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;