import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, CircularProgress, DialogTitle, 
  TextField } from '@material-ui/core';

const NotificationDialogView = ({open, onCancel, onAccept, onChange, message, userId, sendingNotification}) => {
  const [error, setError] = useState(false)
  return (
    <Dialog 
      fullWidth
      open={open}
    >
      <DialogTitle>Enviar notificación</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          fullWidth
          required
          label={userId != '0' ? "Mensaje de notificacion" : "Mensaje de notificacion general"}
          variant="outlined"
          onChange={(event) => onChange(event.target.value)}
          error={error}
          helperText="Favor de escribir la notificación."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}  color="secondary">
          Cancelar
        </Button>
        {
          sendingNotification
          ?
            <CircularProgress color="secondary" />
          :
            <Button onClick={() => {
              if(!message) {
                setError(true);
              }
              else {
                setError(false);
                onAccept()
              }
            }}  
            color="secondary" autoFocus
          >
            Aceptar
          </Button>
        }
      </DialogActions>
  </Dialog>
  );
};

export default NotificationDialogView;